import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-71417599"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  style: {
    "background": "white"
  },
  class: "padding20"
};
const _hoisted_2 = {
  class: "flex justify-between"
};
const _hoisted_3 = {
  class: "flex"
};
const _hoisted_4 = {
  class: "flex-wrap"
};
const _hoisted_5 = {
  key: 0
};
const _hoisted_6 = ["src"];
const _hoisted_7 = {
  key: 1
};
const _hoisted_8 = ["src"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_button = _resolveComponent("el-button");
  const _component_el_upload = _resolveComponent("el-upload");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createVNode(_component_el_upload, {
    action: "-",
    class: "avatar-uploader marginLeft10",
    accept: ".png,.jpg,.jpeg",
    limit: 1,
    "http-request": file => $options.beforeAvatarUpload(file, 'exam_card_img_z')
  }, {
    trigger: _withCtx(() => [_createVNode(_component_el_button, {
      type: "primary"
    }, {
      default: _withCtx(() => [_createTextVNode("请上传答题卡正面图片")]),
      _: 1
    })]),
    _: 1
  }, 8, ["http-request"]), _createVNode(_component_el_upload, {
    action: "-",
    class: "avatar-uploader marginLeft50",
    accept: ".png,.jpg,.jpeg",
    limit: 1,
    "http-request": file => $options.beforeAvatarUpload(file, 'exam_card_img_f')
  }, {
    trigger: _withCtx(() => [_createVNode(_component_el_button, {
      type: "primary"
    }, {
      default: _withCtx(() => [_createTextVNode("请上传答题卡反面图片")]),
      _: 1
    })]),
    _: 1
  }, 8, ["http-request"])]), _createElementVNode("div", null, [_createVNode(_component_el_button, {
    onClick: $options.setCardPoint,
    type: "primary"
  }, {
    default: _withCtx(() => [_createTextVNode("题卡标点")]),
    _: 1
  }, 8, ["onClick"]), _createVNode(_component_el_button, {
    onClick: $options.saveCard,
    type: "success",
    disabled: $data.saveShow
  }, {
    default: _withCtx(() => [_createTextVNode("保存")]),
    _: 1
  }, 8, ["onClick", "disabled"])])]), _createElementVNode("div", _hoisted_4, [$data.Info.exam_card_img_z ? (_openBlock(), _createElementBlock("div", _hoisted_5, [_createElementVNode("img", {
    src: _ctx.$root.projectName + '/' + $data.Info.exam_card_img_z,
    style: _normalizeStyle('width:' + ($data.Info.exam_card ? $data.Info.exam_card.width_px : 0) + 'px;height:' + ($data.Info.exam_card ? $data.Info.exam_card.height_px : 0) + 'px')
  }, null, 12, _hoisted_6)])) : _createCommentVNode("", true), $data.Info.exam_card_img_f ? (_openBlock(), _createElementBlock("div", _hoisted_7, [_createElementVNode("img", {
    src: _ctx.$root.projectName + '/' + $data.Info.exam_card_img_f,
    style: _normalizeStyle('width:' + ($data.Info.exam_card ? $data.Info.exam_card.width_px : 0) + 'px;height:' + ($data.Info.exam_card ? $data.Info.exam_card.height_px : 0) + 'px')
  }, null, 12, _hoisted_8)])) : _createCommentVNode("", true)])]);
}