import "core-js/modules/es.array.push.js";
import { examInfo, uploadFile, addExam } from "@/api/api";
export default {
  name: "index",
  data() {
    return {
      Info: {
        id: '',
        exam_card_img_z: '',
        exam_card_img_f: ''
      },
      saveShow: true
    };
  },
  mounted() {
    let data = this.$root.useRoute.query;
    this.Info.id = data.id;
    this.getInfo();
  },
  methods: {
    getInfo() {
      examInfo(this.Info).then(res => {
        this.Info = res.data;
        if (res.data) {
          this.saveShow = false;
        }
      });
    },
    beforeAvatarUpload(e, name) {
      let formData = new FormData();
      formData.append('file', e.file);
      uploadFile(formData).then(res => {
        this.Info[name] = res.data;
      });
    },
    setCardPoint() {
      if (!this.Info.exam_card_img_z || !this.Info.exam_card_img_f) {
        this.$root.ElMessage.error('请上传答题卡正反面图片');
        return false;
      }
      examInfo({
        id: this.Info.id
      }).then(res => {
        let data = res.data;
        if (!data.exam_card_img_z || !data.exam_card_img_f) {
          this.$root.ElMessage.error('请先保存后再提卡标点');
          return false;
        }
        let routeUrl = this.$root.useRouter.resolve({
          path: "/examSetting/examCardSock",
          query: {
            id: this.Info.id
          }
        });
        window.open(routeUrl.href, '_blank');
      }).catch(err => {
        console.log('提卡标点查询信息错误', err);
      });
    },
    saveCard() {
      if (!this.Info.exam_card_img_z || !this.Info.exam_card_img_f) {
        this.$root.ElMessage.error('请上传答题卡正反面图片');
        return false;
      }
      addExam(this.Info).then(res => {
        if (res.success) {
          this.$root.ElMessageBox.confirm('保存成功，是否前往模板切割页面，对答题卡模板进行切割描点?', '提示', {
            confirmButtonText: '立即前往',
            cancelButtonText: '稍后操作',
            type: 'success',
            draggable: true
          }).then(() => {
            //this.$root.useRouter.push({path:'/examSetting/examCardSock',query:{id:this.Info.id}});
            this.setCardPoint();
          }).catch(() => {
            this.$root.useRouter.push('/examSetting/examCardList');
          });
        } else {
          this.$root.ElMessage.error(res.msg);
        }
      });
    }
  }
};