import { getQuestionTypeAllList, getKDQuestionList, getSubjectList, saveCardPosition, getCardPositionList } from "@/api/api";
export default {
  name: "examList",
  data() {
    return {
      Info: {
        exam_id: '',
        theCheckQuestionList: []
      },
      dialogFormVisible: false,
      dialogFormVisibleLocal: false,
      List: [],
      sujectList: [],
      questionList: [],
      questionTypeList: [],
      checkQuestionList: [],
      cardInfo: {
        t_type: '',
        num: ''
      },
      searchInfo: {
        subject: '',
        qtpye: '',
        source: ''
      },
      localInfo: {
        type: '',
        num: 1,
        score: ''
      },
      exportIds: ''
    };
  },
  mounted() {
    let data = this.$root.useRoute.query;
    this.Info.exam_id = data.id;
    this.getSubjectList();
    this.getQuestionTypeList();
    this.getCardPositionList();
  },
  methods: {
    reset() {
      this.searchInfo.subject = '';
      this.searchInfo.qtpye = '';
      this.searchInfo.source = '';
    },
    getSubjectList() {
      getSubjectList().then(res => {
        this.sujectList = res.data;
      });
    },
    getCardPositionList() {
      getCardPositionList({
        exam_id: this.Info.exam_id
      }).then(res => {
        this.checkQuestionList = res.data;
        this.splo_data();
      });
    },
    getQuestionTypeList() {
      getQuestionTypeAllList().then(res => {
        this.questionTypeList = res.data;
      });
    },
    getQuestionList() {
      this.checkQuestionList = [];
      getKDQuestionList(this.searchInfo).then(res => {
        this.questionList = res.data;
      });
    },
    addQuestionWindowLocal() {
      this.localInfo = {
        type: '',
        num: 1,
        score: ''
      }; //初始化
      this.dialogFormVisibleLocal = true;
    },
    addQuestionWindowKD() {
      this.dialogFormVisible = true;
    },
    resetCard() {
      //重置
      this.$root.msgTalk('此操作将清楚该题卡的所有信息，请谨慎操作，你确定要继续操作吗？', this, 'resetCardImp');
    },
    resetCardImp() {
      this.Info.theCheckQuestionList = [];
      this.saveCardImp();
    },
    saveCard() {
      this.$root.msgTalk('此操作将覆盖之前该题卡的所有信息，如果你已对题卡进行扫描或标点，请谨慎操作，你确定要继续操作吗？', this, 'saveCardImp');
    },
    saveCardImp() {
      //保存
      saveCardPosition(this.Info).then(res => {
        this.$root.msgResut(res, '', '/examSetting/examCardList');
      });
    },
    closeDiv() {
      this.dialogFormVisible = false;
      this.dialogFormVisibleLocal = false;
    },
    deleteQues(index) {
      this.Info.theCheckQuestionList.splice(index, 1);
    },
    addLocalQuestion() {
      //添加试题本地
      let le = this.Info.theCheckQuestionList.length;
      for (let i = 0; i < this.localInfo.num; i++) {
        this.Info.theCheckQuestionList[le + i] = {
          question_no: parseInt(le + i) + 1,
          score: this.localInfo.score,
          title: '',
          parse: '',
          subject: '',
          qtpye: this.localInfo.type,
          answer: '',
          question_id: ''
        };
        this.closeDiv();
      }
    },
    chooseQuestionIds(e) {
      //选择试题(科大)
      this.checkQuestionList = e;
      this.Info.theCheckQuestionList = [];
      this.splo_data();
    },
    getSum() {
      let sum = 0;
      for (let i in this.Info.theCheckQuestionList) {
        let s = parseFloat(this.Info.theCheckQuestionList[i].score) || 0;
        sum = sum + s;
      }
      return sum;
    },
    splo_data() {
      for (let i in this.checkQuestionList) {
        this.Info.theCheckQuestionList[i] = {
          question_no: this.checkQuestionList[i].question_no || this.checkQuestionList[i].srcQuestionId || parseInt(i) + 1,
          score: this.checkQuestionList[i].score || '',
          question_row: this.checkQuestionList[i].question_row || '',
          question_column: this.checkQuestionList[i].question_column || '',
          title: this.checkQuestionList[i].title,
          parse: this.checkQuestionList[i].parse,
          subject: this.checkQuestionList[i].subject,
          qtpye: this.checkQuestionList[i].qtpye,
          answer: this.checkQuestionList[i].answer || this.checkQuestionList[i].answer1,
          question_id: this.checkQuestionList[i].questionId || this.checkQuestionList[i].question_id
          //questionId:this.checkQuestionList[i].question_id || this.checkQuestionList[i].questionId
        };
      }
    }
  }
};