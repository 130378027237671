import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
import { getExamPage, exportExamOneData } from "@/api/api";
export default {
  name: "examList",
  data() {
    return {
      tableData: [],
      dataInfo: [],
      Info: null,
      dialogFormVisible: false,
      pageInfo: {
        pageSize: 10,
        name: '',
        active_id: '',
        current: 1,
        total: 0
      },
      exportIds: ''
    };
  },
  mounted() {
    let data = this.$root.useRoute.query;
    this.pageInfo.active_id = data.active_id;
    this.getList();
  },
  methods: {
    reset() {
      this.pageInfo.name = '';
      this.pageInfo.user_no = '';
    },
    search() {
      this.pageInfo.pageSize = 10;
      this.pageInfo.current = 1;
      this.getList();
    },
    getList() {
      getExamPage(this.pageInfo).then(res => {
        this.pageInfo.total = parseInt(res.data.total);
        this.tableData = res.data.records;
      });
    },
    exportExamOneData(exam_id, name) {
      exportExamOneData({
        exam_id: exam_id
      }).then(res => {
        const blob = new Blob([res], {
          type: 'application/vnd.ms-excel'
        });
        const link = document.createElement("a");
        link.download = '【' + name + '】成绩报表.xls'; //下载名字
        link.style.display = "none";
        link.href = URL.createObjectURL(blob);
        document.body.appendChild(link); // 将a标签追加到文档对象中
        link.click(); // 模拟点击了a标签，会触发a标签的href的读取，浏览器就会自动下载了
      });
    },
    handleSizeChange(e) {
      //修改每页显示条数
      this.pageInfo.pageSize = e;
      this.pageInfo.current = 1;
      this.getList();
    },
    handleCurrentChange(e) {
      this.pageInfo.current = e;
      this.getList();
    }
  }
};