import {reactive, toRaw} from 'vue'
import {userLoginUser} from "@/api/api";

export const isNumber = (e) =>{
    return Object.prototype.toString.call(e).slice(8,-1) ==='Number'
}

export const isString = (e) =>{
    return Object.prototype.toString.call(e).slice(8,-1) === 'String'
}

export const permissions = reactive({
    permissions: [],
    is_super_admin: 0,
})

export const clearPermissions = () => {
    let getDefault = val => {
        if (Array.isArray(val)) {
            return []
        }
        if (isNumber(val)) {
            return 0
        }
        if (isString(val)) {
            return ''
        }
        return null
    }
    for (let key in toRaw(permissions)) {
        permissions[key] = getDefault(permissions[key])
    }
}

export const refreshPermissions = () => {
    userLoginUser().then(res => {
        if (res.success) {
            for (let key in toRaw(permissions)) {
                permissions[key] = res.data[key]
            }
        }
    })
}

// v-if、v-show 与 v-permission 冲突
// v-if="you_condition && checkPermission('you_permission')"
// v-permission="'you_permission'"
export const checkPermissions = (permission) => {
    let flag = false
    // 是否超级管理员
    if (String(permissions.is_super_admin) === '1') {
        flag = true
    }

    // 不填则直接通过
    if (!permission) {
        flag = true
    }

    // 是否拥有权限
    if (permissions.permissions.includes(permission)) {
        flag = true
    }

    return flag
}

export const permission = (el, binding) => {
    let permission = binding.value
    if (!checkPermissions(permission)) {
        el.style.display = 'none'
    }
}

