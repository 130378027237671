import "core-js/modules/es.array.push.js";
import { getMyExamOcrCheckList } from "@/api/api";
export default {
  name: "examOrcCheckList",
  data() {
    return {
      tableData: [],
      pageInfo: {
        pageSize: 10,
        current: 1,
        total: 0,
        type: 'ocr',
        // 识别：ocr,判卷：check
        active_name: '',
        school_name: '',
        name: ''
      }
    };
  },
  watch: {
    "$route.query.type": {
      handler: function (newVal) {
        this.pageInfo.type = newVal ? newVal : 'ocr';
        this.getList();
      },
      immediate: true,
      deep: true
    }
  },
  mounted() {
    let data = this.$root.useRoute.query;
    this.pageInfo.type = data.type || 'ocr';
    //
    this.getList();
  },
  methods: {
    reset() {
      this.pageInfo.name = '';
    },
    search() {
      this.pageInfo.pageSize = 10;
      this.pageInfo.current = 1;
      this.getList();
    },
    getList() {
      getMyExamOcrCheckList(this.pageInfo).then(res => {
        this.pageInfo.total = parseInt(res.data.total);
        this.tableData = res.data.data;
      });
    },
    handleSizeChange(e) {
      //修改每页显示条数
      this.pageInfo.pageSize = e;
      this.pageInfo.current = 1;
      this.getList();
    },
    handleCurrentChange(e) {
      this.pageInfo.current = e;
      this.getList();
    },
    toPage(key, item) {
      if (key === 'ocr') {
        this.$root.useRouter.push({
          path: `/examOcr/examOcrInfo/${item.id}`
        });
      }
      if (key === 'check') {
        console.log('aaaaaaa');
        this.$root.useRouter.push({
          path: `/examOcr/examCheckInfo/${item.id}`
        });
      }
    }
  }
};