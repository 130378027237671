// import {getIndexData} from "@/api/api";

export default {
  name: "index",
  data() {
    return {
      indexInfo: {
        buildingInfos: []
      }
    };
  },
  mounted() {
    //this.getList();
  },
  methods: {
    // getList() {
    //   getIndexData().then(res => {
    //     this.indexInfo = res.data;
    //   })
    // }
  }
};