export const projectName = process.env.NODE_ENV === 'development' ? 'api' : '';

// 用户类型
export const USER_TYPE_ADMIN = 1
export const USER_TYPE_SCHOOL_ADMIN = 2
export const USER_TYPE_TEACHER = 3
export const USER_TYPE_STUDENT = 4
export const userType = [
  {type: USER_TYPE_ADMIN, name: '系统管理员'},
  {type: USER_TYPE_SCHOOL_ADMIN, name: '学校管理员'},
  {type: USER_TYPE_TEACHER, name: '教师'},
  {type: USER_TYPE_STUDENT, name: '学生'}
];

export const subjectType = [
  {type: 1, name: '单科'},
  {type: 2, name: '多科综合'}
];

// 答题卡(后台接口请求，废弃)
export const examCardType = [
  {id: 1, name: 'A4'},
  {id: 2, name: 'A3三栏'},
  {id: 3, name: 'A3两栏'},
  {id: 4, name: '新A3(1280*900)'},
];

export const examNoInputType = [
  {type: 1, name: '手涂'},
  {type: 2, name: '条码'},
  {type: 3, name: '手写'},
  {type: 4, name: '手写(MathPix)'}
];


export const paiweiType = [
  {type: 1, name: '学号作为考号'},
  {type: 2, name: '随机生成考号'},
  {type: 3, name: '自行导入考号'}
];
