import "core-js/modules/es.array.push.js";
import { getMyExamCheckQuesList } from "@/api/api";
export default {
  name: "examList",
  data() {
    return {
      tableData: [],
      dataInfo: [],
      Info: null,
      dialogFormVisible: false,
      pageInfo: {
        pageSize: 10,
        name: '',
        current: 1,
        total: 0
      },
      exam_id: ''
    };
  },
  mounted() {
    let data = this.$root.useRoute.query;
    this.exam_id = data.examId;
    this.getList();
  },
  methods: {
    // 获取行的颜色
    getRowClassName({
      row
    }) {
      if (row.countWill > 0) {
        return 'my-row-red';
      }
    },
    reset() {
      this.pageInfo.name = '';
    },
    search() {
      this.pageInfo.pageSize = 10;
      this.pageInfo.current = 1;
      this.getList();
    },
    getList() {
      getMyExamCheckQuesList({
        exam_id: this.exam_id
      }).then(res => {
        this.tableData = res.data;
      });
    },
    showEdit(question_no) {
      let url = '/examCheck/studentAnswerInfo?exam_id=' + this.exam_id + '&question_no=' + question_no;
      window.localStorage.setItem('menuUrl', url);
      this.$root.useRouter.push({
        path: "/examCheck/studentAnswerInfo",
        query: {
          exam_id: this.exam_id,
          question_no: question_no
        }
      });
    },
    closeDiv() {
      this.dialogFormVisible = false;
    }
  }
};