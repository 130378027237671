import "core-js/modules/es.array.push.js";
import { getPermissionInfo, getPermissionMenus, roleStore } from "@/api/api";
export default {
  name: "roleEdit",
  data() {
    return {
      token: localStorage.getItem('token'),
      Info: {
        id: '',
        name: '',
        menu_ids: ''
      },
      //
      permissionList: [],
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      pageForm: {
        id: undefined,
        name: '',
        permissions: [],
        menus: []
      },
      form_loading: false
    };
  },
  mounted() {
    let data = this.$root.useRoute.query.Info;
    if (data) {
      this.Info = JSON.parse(this.$root.useRoute.query.Info);
      if (Reflect.has(this.Info, 'id')) {
        this.pageForm.name = this.Info.name;
        getPermissionInfo(this.Info.id).then(({
          data: res
        }) => {
          const {
            roleMenus,
            rolePermissions
          } = res;
          roleMenus.forEach(iter => {
            this.pageForm.menus.push(`menu_${iter.menu_id}`);
          });
          rolePermissions.forEach(iter => {
            this.pageForm.permissions.push(`permissions_${iter.permission_id}`);
          });
          this.$nextTick(() => {
            const selects = [...this.pageForm.permissions, ...this.pageForm.menus];
            this.$refs.tree.setCheckedKeys(selects);
          });
        });
      }
    }
    this.getMenuOptions();
  },
  methods: {
    getId() {
      var _this$Info;
      return (this === null || this === void 0 || (_this$Info = this.Info) === null || _this$Info === void 0 ? void 0 : _this$Info.id) || null;
    },
    getMenuOptions() {
      getPermissionMenus().then(({
        data: res
      }) => {
        const permissionList = [];
        const f1 = [];
        const f2 = [];
        const f3 = [];
        res.forEach((item, index1) => {
          // 二级菜单操作
          const getSecondPermissions = (permissions, index2) => {
            if (!permissions) {
              return [];
            }
            const children = [];
            permissions.forEach((iter, index3) => {
              children.push({
                id: `permissions_${iter.id}`,
                isMenu: false,
                name: iter.title,
                index1: index1,
                index2: index2,
                index3: index3,
                menuAttribute: null,
                permissionsAttribute: {
                  ...iter
                },
                children: []
              });
              f3.push(`permissions_${iter.id}`);
            });
            return children;
          };

          // 二级菜单
          const getSecond = menus => {
            const secondChildren = [];
            menus.forEach((second, index2) => {
              secondChildren.push({
                id: `menu_${second.id}`,
                isMenu: true,
                name: second.title,
                index1: index1,
                index2: index2,
                index3: null,
                menuAttribute: {
                  name: second.title,
                  uri: second.url,
                  is_supper_admin: null
                },
                permissionsAttribute: null,
                children: getSecondPermissions(second.permissions, index2)
              });
              f2.push(`menu_${second.id}`);
            });
            return secondChildren;
          };

          // 一级菜单
          permissionList.push({
            id: `menu_${item.id}`,
            isMenu: true,
            name: item.title,
            index1: index1,
            index2: null,
            index3: null,
            menuAttribute: {
              name: item.title,
              is_supper_admin: item.is_supper_admin || null
            },
            permissionsAttribute: null,
            children: getSecond(item.child || [])
          });
          f1.push(`first_${item.id}`);
        });
        //
        console.log(permissionList, '-------permissionList--');
        // console.log(f1)
        // console.log(f2)
        // console.log(f3)
        this.permissionList = permissionList;
        // 更新样式
        this.updateLeafNodeStyle();
      });
    },
    checkChange(p1, p2) {
      // console.log(p1, p2, '-------p1, p2-');
      //
      const checked = p2.checkedKeys.includes(p1.id);
      // console.log(checked, '-----checked-');
      const selects = p2.checkedKeys;
      if (checked) {
        const {
          index1,
          index2,
          index3
        } = p1;
        // this.permissionList
        if (index3 != null) {
          var _this$permissionList$, _this$permissionList$2;
          const i1Id = (_this$permissionList$ = this.permissionList[index1]) === null || _this$permissionList$ === void 0 ? void 0 : _this$permissionList$.id;
          const i2Id = (_this$permissionList$2 = this.permissionList[index1]) === null || _this$permissionList$2 === void 0 || (_this$permissionList$2 = _this$permissionList$2.children[index2]) === null || _this$permissionList$2 === void 0 ? void 0 : _this$permissionList$2.id;
          if (!p2.checkedKeys.includes(i1Id)) {
            selects.push(i1Id);
          }
          if (!p2.checkedKeys.includes(i2Id)) {
            selects.push(i2Id);
          }
        } else if (index2 != null) {
          var _this$permissionList$3;
          const i1Id = (_this$permissionList$3 = this.permissionList[index1]) === null || _this$permissionList$3 === void 0 ? void 0 : _this$permissionList$3.id;
          if (!p2.checkedKeys.includes(i1Id)) {
            selects.push(i1Id);
          }
        }
        // console.log(selects, '--------selects-');
        this.$nextTick(() => {
          this.$refs.tree.setCheckedKeys(selects);
        });
      }
    },
    updateLeafNodeStyle(delayed = false) {
      const findParentWithClass = function (className, element) {
        while (element) {
          if (element.classList && element.classList.contains(className)) {
            return element;
          }
          element = element.parentElement;
        }
        return null;
      };
      const elementHandler = () => {
        document.querySelectorAll('.permission-item').forEach(iter => {
          const result = findParentWithClass('el-tree-node__children', iter);
          if (result !== null) {
            if (result.style.display !== 'flex') {
              result.style.display = 'flex';
              result.style.flexWrap = 'wrap';
            }
          }
        });
      };
      if (delayed) {
        this.$nextTick(() => {
          setTimeout(() => {
            elementHandler();
          }, 50);
        });
      } else {
        this.$nextTick(() => {
          elementHandler();
        });
      }
    },
    qxBtn() {
      this.$root.useRouter.go(-1);
    },
    subData() {
      //提交
      const getParams = () => {
        const re = this.$refs.tree.getCheckedNodes(false, true);
        const menus = [];
        const permissions = [];
        re.forEach(iter => {
          if (iter.id.startsWith('menu_')) {
            menus.push(Number(iter.id.slice(5)));
          } else if (iter.id.startsWith('permissions_')) {
            permissions.push(Number(iter.id.slice(12)));
          }
        });
        return {
          menus,
          permissions
        };
      };
      this.$nextTick(() => {
        const {
          menus,
          permissions
        } = getParams();
        // console.log('menus and permissions')
        // console.log(menus)
        // console.log(permissions)
        this.pageForm.menus = menus;
        this.pageForm.permissions = permissions;
        if (this.pageForm.permissions.length === 0 && this.pageForm.menus.length === 0) {
          this.$message.warning('请选择角色权限');
          return;
        }
        if (!this.pageForm.name) {
          this.$message.warning('请填写角色信息');
          return;
        }
        let handler = roleStore;
        if (this.getId() && this.getId() != null) {
          this.pageForm.id = this.getId();
        }
        handler(this.pageForm).then(res => {
          if (res.success) {
            this.form_loading = false;
            this.$message.success('操作成功');
            setTimeout(() => {
              this.$root.useRouter.go(-1);
            }, 2000);
          } else {
            this.$message.warning(res.msg);
            this.form_loading = false;
          }
        }).catch(() => {
          this.form_loading = false;
        });
      });
    }
  }
};