import { getKnowCardPage, EditKnowExamNo } from "@/api/api";
export default {
  name: "examList",
  data() {
    return {
      tableData: [],
      dataInfo: [],
      changeInfo: {
        id: '',
        exam_no: '',
        exam_no_new: ''
      },
      dialogFormVisible: false,
      pageInfo: {
        pageSize: 10,
        exam_id: '',
        current: 1,
        show_type: 'error',
        total: 0
      },
      exportIds: '',
      //
      dialogImageVisible: false,
      curItem: null
    };
  },
  mounted() {
    let data = this.$root.useRoute.query;
    if (data.exam_id) {
      this.pageInfo.exam_id = data.exam_id;
      this.getList();
    }
  },
  methods: {
    handleShowImage(item) {
      this.curItem = item;
      this.dialogImageVisible = true;
    },
    getRowClassName({
      row
    }) {
      if (this.pageInfo.show_type === 'all' && row.text) {
        return 'my-row-red';
      }
    },
    reset() {
      this.pageInfo.show_type = 'error';
    },
    search() {
      this.pageInfo.pageSize = 10;
      this.pageInfo.current = 1;
      this.getList();
    },
    getList() {
      this.closeDiv();
      getKnowCardPage(this.pageInfo).then(res => {
        this.pageInfo.total = parseInt(res.data.total);
        this.tableData = res.data.records;
      });
    },
    changeExamNo(id, exam_no) {
      this.dialogFormVisible = true;
      this.changeInfo = {
        id: id,
        exam_id: this.pageInfo.exam_id,
        exam_no: exam_no,
        exam_no_new: exam_no
      };
    },
    onSubmit() {
      if (!this.changeInfo.exam_no_new) {
        this.$root.ElMessage.error('请填写考号');
        return false;
      }
      EditKnowExamNo(this.changeInfo).then(res => {
        this.$root.msgResut(res, this, 'getList');
      });
    },
    closeDiv() {
      this.dialogFormVisible = false;
      this.dialogImageVisible = false;
      this.curItem = null;
    },
    ER(imgUrl, fileName) {
      let url = '';
      let path = '';
      if (imgUrl) {
        let h = imgUrl.split('/img/' + fileName);
        path = h[0];
      }
      if (fileName && path) {
        let s = fileName.split('.');
        url = path + '/checkImg/' + s[0] + 'SORT0TINO0.' + s[1];
      }
      return url;
    },
    handleSizeChange(e) {
      //修改每页显示条数
      this.pageInfo.pageSize = e;
      this.pageInfo.current = 1;
      this.getList();
    },
    handleCurrentChange(e) {
      this.pageInfo.current = e;
      this.getList();
    }
  }
};