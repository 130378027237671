import "core-js/modules/es.array.push.js";
import { getExamUserQuestionPage } from "@/api/api";
export default {
  name: "examList",
  data() {
    return {
      tableData: [],
      dataInfo: [],
      Info: null,
      dialogFormVisible: false,
      examData: {
        exam_id: '',
        user_id: ''
      },
      exportIds: ''
    };
  },
  mounted() {
    let data = this.$root.useRoute.query;
    this.examData.exam_id = data.exam_id;
    this.examData.user_id = data.user_id;
    this.getList();
  },
  methods: {
    reset() {
      this.pageInfo.name = '';
      this.pageInfo.user_no = '';
    },
    search() {
      this.pageInfo.pageSize = 10;
      this.pageInfo.current = 1;
      this.getList();
    },
    getList() {
      getExamUserQuestionPage(this.examData).then(res => {
        this.tableData = res.data;
      });
    },
    showEdit(id) {
      let url = "/examData/examStudentQuestionInfo?id=" + id + '&exam_id=' + this.examData.exam_id + '&user_id=' + this.examData.user_id;
      window.localStorage.setItem('menuUrl', url);
      this.$root.useRouter.push({
        path: "/examData/examStudentQuestionInfo",
        query: {
          id: id,
          exam_id: this.examData.exam_id,
          user_id: this.examData.user_id
        }
      });
    }
  }
};