import "core-js/modules/es.array.push.js";
import { getCheckTaskQuestion, getCheckTeacherList, paperCheckTeacherTask } from "@/api/api";
export default {
  name: "examList",
  data() {
    return {
      tableData: [],
      dataInfo: [],
      Info: null,
      dialogFormVisible: false,
      pageInfo: {
        name: ''
      },
      formInfo: {
        checkTeacherIds: [],
        exam_id: '',
        question_no: '',
        time_range: []
      },
      exportIds: ''
    };
  },
  mounted() {
    let data = this.$root.useRoute.query;
    this.formInfo.exam_id = data.id;
    this.getList();
    this.getTeacherList();
  },
  methods: {
    getRowClassName({
      row
    }) {
      if (row.exam_result_check_finish_count < row.exam_result_check_count) {
        return 'my-row-red';
      }
    },
    reset() {
      this.pageInfo.name = '';
      this.pageInfo.user_no = '';
    },
    search() {
      this.getList();
    },
    timeRangeChange(e) {
      this.formInfo.start_time = e[0];
      this.formInfo.end_time = e[1];
    },
    getList() {
      this.dialogFormVisible = false;
      getCheckTaskQuestion({
        exam_id: this.formInfo.exam_id
      }).then(res => {
        this.tableData = res.data;
      });
    },
    getTeacherList() {
      getCheckTeacherList({
        exam_id: this.formInfo.exam_id
      }).then(res => {
        let subarr = [];
        for (let i in res.data) {
          subarr.push({
            label: res.data[i].name,
            value: res.data[i].id
          });
        }
        this.teacherList = subarr;
        /*if(this.Info.subject_ids){
          let subjectIds=this.Info.subject_ids.split(",");
          this.subjectIds=subjectIds.map(function(data){
            return +data;
          });
        }*/
      });
    },
    showRenew(row) {
      this.formInfo.question_no = row.question_no;
      this.formInfo.checkTeacherIds = row.checkTeacherIds;
      this.formInfo.start_time = row.start_time;
      this.formInfo.end_time = row.end_time;
      this.formInfo.time_range = [row.start_time, row.end_time];
      this.dialogFormVisible = true;
    },
    closeDiv() {
      this.dialogFormVisible = false;
    },
    subData() {
      if (!this.formInfo.start_time || !this.formInfo.end_time) {
        this.$root.ElMessage.error('请选择开始和结束时间！');
        return false;
      }
      paperCheckTeacherTask(this.formInfo).then(res => {
        this.$root.msgResut(res, this, 'getList');
      });
    }
  }
};