import "core-js/modules/es.array.push.js";
import { ckExamPage } from "@/api/api";
export default {
  name: "examList",
  data() {
    return {
      tableData: [],
      dataInfo: [],
      Info: null,
      dialogFormVisible: false,
      pageInfo: {
        pageSize: 10,
        current: 1,
        total: 0,
        school_name: '',
        active_name: '',
        name: ''
      },
      exportIds: ''
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    reset() {
      this.pageInfo.school_name = '';
      this.pageInfo.active_name = '';
      this.pageInfo.name = '';
    },
    search() {
      this.pageInfo.pageSize = 10;
      this.pageInfo.current = 1;
      this.getList();
    },
    getList() {
      ckExamPage(this.pageInfo).then(res => {
        let data = res.data;
        this.pageInfo.total = parseInt(data.total);
        this.tableData = data.data;
        this.pageInfo.current = Number(data.current_page);
      });
    },
    toQuestionPage(row) {
      this.$root.useRouter.push({
        path: `/checkManage/examQuestions/${row.id}`
      });
    },
    handleSizeChange(e) {
      //修改每页显示条数
      this.pageInfo.pageSize = e;
      this.pageInfo.current = 1;
      this.getList();
    },
    handleCurrentChange(e) {
      this.pageInfo.current = e;
      this.getList();
    }
  }
};