import "core-js/modules/es.array.push.js";
import "core-js/modules/web.url-search-params.delete.js";
import "core-js/modules/web.url-search-params.has.js";
import "core-js/modules/web.url-search-params.size.js";
import { syncPaperCheckData, getExamUserPage, exportExamData, statExamScore } from "@/api/api";
export default {
  name: "examList",
  data() {
    return {
      tableData: [],
      dataInfo: [],
      Info: null,
      dialogFormVisible: false,
      pageInfo: {
        pageSize: 10,
        student_name: '',
        exam_no: '',
        exam_id: '',
        active_id: '',
        current: 1,
        total: 0
      },
      examData: {
        exam_id: '',
        active_id: ''
      },
      exportIds: ''
    };
  },
  mounted() {
    let data = this.$root.useRoute.query;
    this.pageInfo.exam_id = data.examId;
    this.pageInfo.active_id = data.activeId;
    this.examData.exam_id = data.examId;
    this.examData.active_id = data.activeId;
    this.getList();
  },
  methods: {
    reset() {
      this.pageInfo.student_name = '';
      this.pageInfo.exam_no = '';
    },
    search() {
      this.pageInfo.pageSize = 10;
      this.pageInfo.current = 1;
      this.getList();
    },
    getList() {
      getExamUserPage(this.pageInfo).then(res => {
        this.pageInfo.total = parseInt(res.data.total);
        this.tableData = res.data.records;
      });
    },
    getExamCardData() {
      //同步
      syncPaperCheckData(this.examData).then(res => {
        this.$root.ElMessageBox.alert(res.msg);
        if (res.success) {
          this.reset();
        }
      });
    },
    statScores() {
      statExamScore(this.examData).then(res => {
        this.$root.ElMessageBox.alert(res.msg);
        if (res.success) {
          this.reset();
        }
      });
    },
    exportData() {
      exportExamData({
        exam_id: this.examData.exam_id
      }).then(res => {
        const blob = new Blob([res], {
          type: 'application/vnd.ms-excel'
        });
        const link = document.createElement("a");
        link.download = '成绩.xls'; //下载名字
        link.style.display = "none";
        link.href = URL.createObjectURL(blob);
        document.body.appendChild(link);
        link.click();
      }).catch(err => {
        console.log('导出失败', err);
      });
    },
    showEdit(studentId, examId) {
      let url = '/examData/examStudentQuestionList?user_id=' + studentId + '&exam_id=' + examId;
      window.localStorage.setItem('menuUrl', url);
      this.$root.useRouter.push({
        path: "/examData/examStudentQuestionList",
        query: {
          user_id: studentId,
          exam_id: examId
        }
      });
    },
    handleSizeChange(e) {
      //修改每页显示条数
      this.pageInfo.pageSize = e;
      this.pageInfo.current = 1;
      this.getList();
    },
    handleCurrentChange(e) {
      this.pageInfo.current = e;
      this.getList();
    }
  }
};