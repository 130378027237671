import { pianyiResultInfo } from "@/api/api";
export default {
  name: "examList",
  data() {
    return {
      tableData: [],
      Info: [],
      changeInfo: {
        id: '',
        exam_no: '',
        exam_no_new: ''
      },
      dialogFormVisible: false,
      pageInfo: {
        pageSize: 10,
        exam_id: '',
        current: 1,
        total: 0
      },
      exportIds: ''
    };
  },
  mounted() {
    let data = this.$root.useRoute.query;
    if (data.exam_id) {
      this.pageInfo.exam_id = data.exam_id;
      this.getList();
    }
  },
  methods: {
    reset() {
      this.pageInfo.name = '';
      this.pageInfo.user_no = '';
    },
    search() {
      this.pageInfo.pageSize = 10;
      this.pageInfo.current = 1;
      this.getList();
    },
    getList() {
      pianyiResultInfo(this.pageInfo).then(res => {
        this.Info = res.data;
      });
    },
    changeExamNo(id, exam_no) {
      this.dialogFormVisible = true;
      this.changeInfo = {
        id: id,
        exam_id: this.pageInfo.exam_id,
        exam_no: exam_no,
        exam_no_new: exam_no
      };
    },
    closeDiv() {
      this.dialogFormVisible = false;
    },
    ER(imgUrl, fileName) {
      let url = '';
      let path = '';
      if (imgUrl) {
        let h = imgUrl.split('/img/' + fileName);
        path = h[0];
      }
      if (fileName && path) {
        let s = fileName.split('.');
        url = path + '/checkImg/' + s[0] + 'SORT0TINO0.' + s[1];
      }
      return url;
    },
    handleSizeChange(e) {
      //修改每页显示条数
      this.pageInfo.pageSize = e;
      this.pageInfo.current = 1;
      this.getList();
    },
    handleCurrentChange(e) {
      this.pageInfo.current = e;
      this.getList();
    }
  }
};