import "core-js/modules/es.array.push.js";
import { getMyMenu, loginOut, changePass } from "@/api/api.js";
export default {
  data() {
    return {
      menuList: [],
      menuIndex: localStorage.getItem('menuIndex') || '0-0',
      title1: localStorage.getItem('title1'),
      title2: localStorage.getItem('title2'),
      clientHeight: `${document.documentElement.clientHeight}`,
      user: [],
      isZhankai: true,
      passShow: false,
      passInfo: {
        oldPassword: '',
        newPassword: '',
        twoNewPassword: ''
      }
    };
  },
  created() {},
  mounted() {
    // this.$root 获取App.vue中的变量和方法
    let cwidth = `${document.documentElement.clientWidth}`;
    this.user = JSON.parse(localStorage.getItem('user'));
    if (cwidth < 1200) {
      this.isZhankai = false;
    }
    this.getMenuList();
    setTimeout(() => {
      this.menuList = this.$root.menuList;
    }, 200);
  },
  methods: {
    goTo(index, url, title1, title2) {
      if (url) {
        window.localStorage.setItem('menuIndex', index);
        window.localStorage.setItem('menuUrl', url);
        window.localStorage.setItem('title1', title1);
        window.localStorage.setItem('title2', title2);
        this.title1 = title1;
        this.title2 = title2;
        this.$root.useRouter.push(url);
      }
    },
    changePass() {
      this.passShow = true;
    },
    closeDiv() {
      this.passShow = false;
    },
    subData() {
      if (!this.passInfo.oldPassword || !this.passInfo.newPassword || !this.passInfo.twoNewPassword) {
        this.$root.ElMessage.error('密码不能为空');
        return false;
      }
      if (this.passInfo.newPassword != this.passInfo.twoNewPassword) {
        this.$root.ElMessage.error('两次密码不一致');
        return false;
      }
      changePass(this.passInfo).then(res => {
        if (res.success) {
          this.$root.ElMessage({
            type: 'success',
            message: '密码修改成功，请重新登录！'
          });
          loginOut();
          this.$root.useRouter.push('/');
        } else {
          this.$root.ElMessage.error(res.msg);
        }
      });
    },
    zhankai() {
      this.isZhankai = !this.isZhankai;
      this.$forceUpdate();
    },
    loginOut() {
      this.$root.ElMessageBox.confirm('你确定要退出吗?', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning',
        draggable: true
      }).then(() => {
        loginOut();
        this.$root.useRouter.push('/');
      });
    },
    getMenuList() {
      getMyMenu().then(res => {
        this.menuList = res.data;
        this.$root.menuList = res.data;
      });
    }
  }
};