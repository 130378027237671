import "core-js/modules/es.array.push.js";
import { getStudentQuestionInfo, saveChangeScore } from "@/api/api";
export default {
  name: "examList",
  data() {
    return {
      Info: {
        id: '',
        score: 0
      },
      examData: {
        exam_id: '',
        user_id: ''
      }
    };
  },
  mounted() {
    let data = this.$root.useRoute.query;
    this.Info.id = data.id;
    this.examData.exam_id = data.exam_id;
    this.examData.user_id = data.user_id;
    this.getInfo();
  },
  methods: {
    reset() {
      this.searchInfo.subject = '';
      this.searchInfo.qtpye = '';
      this.searchInfo.source = '';
    },
    search() {
      this.getList();
    },
    getInfo() {
      getStudentQuestionInfo(this.Info).then(res => {
        this.Info = res.data;
      });
    },
    showEdit(id) {
      this.$root.useRouter.push({
        path: "/exam/examEdit",
        query: {
          id: id
        }
      });
    },
    showRenew(id) {
      this.dialogFormVisible1 = true;
      this.$refs.renewDiv.getInfo(id); //调用子组件方法
    },
    addQuestionWindow() {
      this.dialogFormVisible = true;
    },
    saveScore() {
      //保存
      saveChangeScore({
        id: this.Info.id,
        score: this.Info.score,
        is_judge_ok: 1
      }).then(res => {
        this.$root.msgResut(res, this, 'goFor');
      });
    },
    goFor() {
      let url = '/examData/examStudentQuestionList?user_id=' + this.examData.user_id + '&exam_id=' + this.examData.exam_id;
      window.localStorage.setItem('menuUrl', url);
      this.$root.useRouter.push({
        path: "/examData/examStudentQuestionList",
        query: {
          user_id: this.examData.user_id,
          exam_id: this.examData.exam_id
        }
      });
    }
  }
};