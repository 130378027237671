import {createRouter, createWebHashHistory} from 'vue-router' //createWebHashHistory
import Login from '@/view/login.vue'
import Home from '@/view/home.vue'
import Index from '@/view/index.vue'
import roleEdit from '@/view/user/roleEdit.vue'
import examEdit from '@/view/exam/examEdit.vue'
import userEdit from '@/view/user/userEdit.vue'
import examSortDo from '@/view/examSetting/examSortDo'
import examSortUser from '@/view/examSetting/examSortUser'
import examCardEdit from '@/view/examSetting/examCardEdit.vue'
import examCardSock from '@/view/examSetting/examCardSock.vue'
import examStudentList from '@/view/examData/examStudentList.vue'
import examStudentQuestionList from '@/view/examData/examStudentQuestionList.vue'
import examStudentQuestionInfo from '@/view/examData/examStudentQuestionInfo.vue'
import examCardModel from '@/view/examSetting/examCardModel.vue'
import KnowResultList from '@/view/examCard/knowResultList.vue'
import PaperCheckTaskQuesList from '@/view/examSetting/paperCheckTaskQuesList'
import ExamCheckQuesList from '@/view/examCheck/examCheckQuesList'
import StudentAnswerInfo from '@/view/examCheck/studentAnswerInfo'
import ActiveExamScoreOne from '@/view/examData/activeExamScoreOne'
import PianyiResultInfo from '@/view/examCard/pianyiResultInfo'
import examOcrCheckList from '@/view/examOcrCheck/examOcrCheckList'
import examOcrInfo from '@/view/examOcrCheck/examOcrInfo'
import examCheckInfo from '@/view/examOcrCheck/examCheckInfo'
import examTableList from '@/view/checkManage/examTableList'
import examQuestionList from '@/view/checkManage/examQuestionList'

export default createRouter({
  history: createWebHashHistory(),
  //动态注册路由
  routes: [
    {
      path: '/',
      name: 'Login',
      component: Login
    },
    {
      path: '/home',
      name: 'Home',
      component: Home,
      redirect: '/index',
      children: [
        {
          path: '/index',
          name: 'Index',
          component: Index
        },
        {
          path: '/user/roleEdit',
          name: 'roleEdit',
          component: roleEdit
        },
        {
          path: '/exam/examEdit',
          name: 'examEdit',
          component: examEdit
        },
        {
          path: '/user/userEdit',
          name: 'userEdit',
          component: userEdit
        },
        {
          path: '/examSetting/examSortDo',
          name: 'examSortDo',
          component: examSortDo
        },
        {
          path: '/examSetting/examSortUser',
          name: 'examSortUser',
          component: examSortUser
        },
        {
          path: '/examSetting/examCardEdit',
          name: 'examCardEdit',
          component: examCardEdit
        },
        {
          path: '/examData/examStudentList',
          name: 'examStudentList',
          component: examStudentList
        },
        {
          path: '/examData/examStudentQuestionList',
          name: 'examStudentQuestionList',
          component: examStudentQuestionList
        },
        {
          path: '/examData/examStudentQuestionInfo',
          name: 'examStudentQuestionInfo',
          component: examStudentQuestionInfo
        },
        {
          path: '/examSetting/examCardModel',
          name: 'examCardModel',
          component: examCardModel
        },
        {
          path: '/examCard/knowResultList',
          name: 'KnowResultList',
          component: KnowResultList
        },
        {
          path: '/examSetting/paperCheckTaskQuesList',
          name: 'PaperCheckTaskQuesList',
          component: PaperCheckTaskQuesList
        },
        {
          path: '/examCheck/examCheckQuesList',
          name: 'ExamCheckQuesList',
          component: ExamCheckQuesList
        },
        {
          path: '/examCheck/studentAnswerInfo',
          name: 'StudentAnswerInfo',
          component: StudentAnswerInfo
        },
        {
          path: '/examData/activeExamScoreOne',
          name: 'ActiveExamScoreOne',
          component: ActiveExamScoreOne
        },
        {
          path: '/examCard/pianyiResultInfo',
          name: 'PianyiResultInfo',
          component: PianyiResultInfo
        },
        {
          path: '/examOcr/examOcrCheckList',
          name: 'ExamOcrCheckList',
          component: examOcrCheckList
        },
        {
          path: '/examOcr/examOcrInfo/:exam_id',
          name: 'ExamOcrInfo',
          component: examOcrInfo
        },
        {
          path: '/examOcr/examCheckInfo/:exam_id',
          name: 'checkManage.examCheckInfo',
          component: examCheckInfo
        },
        //
        {
          path: '/checkManage/examTableList',
          name: 'checkManage.examTableList',
          component: examTableList
        },
        {
          path: '/checkManage/examQuestions/:examId',
          name: 'checkManage.examQuestions',
          component: examQuestionList
        },
        // 学情分析
        {
          path: '/xueqing/activeTableList',
          name: 'xueqing.activeTableList',
          component: () => import('@/view/xueqing/activeTable.vue'),
        },
        { // 学情看板
          path: '/xueqing/xqBoard/:active_id',
          name: 'xueqing.xqBoard',
          component: () => import('@/view/xueqing/xqBoard.vue'),
        }, {
          path: '/xueqing/xqLineChart/:active_id',
          name: 'xueqing.xqLineChart',
          component: () => import('@/view/xueqing/xqLineChart.vue'),
        },
        // 学科统计
        {
          path: '/xueqing/xqStat/:id',
          name: 'xueqing.xqStat',
          component: () => import('@/view/xueqing/xqStat.vue'),
        },
        {//学情分析-考试列表
          path: '/xueqing/xqQuestions/:active_id',
          name: 'xueqing.xqExamList',
          component: () => import('@/view/xueqing/xqQuestions.vue'),
        },
        {
          path: '/setting/classTeacherList',
          name: 'classTeacherList',
          component: () => import('@/view/school/clazzTeacherList.vue'),
        },
        {
          path: '/setting/resultCommentList',
          name: 'resultCommentList',
          component: () => import('@/view/examData/resultCommentList.vue'),
        },
      ]
    },
    {
      path: '/examSetting/examCardSock',
      name: 'examCardSock',
      component: examCardSock
    },
  ],
})
