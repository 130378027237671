import { examPaiwei } from "@/api/api";
import { paiweiType } from "@/utils/config";
export default {
  name: "index",
  data() {
    return {
      token: localStorage.getItem('token'),
      Info: {
        active_id: '',
        type: '',
        num: '',
        file: '',
        fileName: ''
      },
      paiweiType: paiweiType,
      rules: {
        type: [{
          required: true,
          message: '请选择生成考号类型',
          trigger: 'blur'
        }]
      }
    };
  },
  mounted() {
    let data = this.$root.useRoute.query;
    this.Info.active_id = data.id;
  },
  methods: {
    closeDiv() {
      this.$root.useRouter.go(-1);
    },
    subData() {
      //提交
      this.$refs['Info'].validate(valid => {
        if (valid) {
          let formData = new FormData();
          formData.append('active_id', this.Info.active_id);
          formData.append('file', this.Info.file);
          formData.append('type', this.Info.type);
          formData.append('num', this.Info.num);
          examPaiwei(formData).then(res => {
            if (res.success) {
              this.$root.ElMessage({
                type: 'success',
                message: res.msg
              });
              this.closeDiv();
            } else {
              this.$root.ElMessage.error(res.msg);
            }
          });
        }
      });
    },
    beforeAvatarUpload(e) {
      this.Info.file = e;
      this.Info.fileName = e.name;
    }
  }
};