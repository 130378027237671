import "core-js/modules/es.array.push.js";
import { adminRoleOptions, examQuestPage, roleUserOptions, saveCheckTask } from "@/api/api";
export default {
  name: "examQuestionList",
  data() {
    return {
      pageInfo: {
        pageSize: 20,
        current: 1,
        total: 0
      },
      tableData: [],
      Info: null,
      // 当前数据
      exam_id: '',
      dialogVisible: false,
      pageForm: {
        exam_id: '',
        question_id: '',
        ocr_role_id: '',
        check_role_id: '',
        ocr_user: [],
        check_user: []
      },
      curItem: null,
      role_options: [],
      ocrUsers: [],
      checkUsers: []
    };
  },
  computed: {
    ocrIds() {
      let ids = [];
      this.pageForm.ocr_user.map(item => {
        ids.push(Number(item.user_id));
      });
      return ids;
    },
    checkIds() {
      let ids = [];
      this.pageForm.check_user.map(item => {
        ids.push(Number(item.user_id));
      });
      return ids;
    }
  },
  mounted() {
    this.getRoleOptions();
    let {
      examId
    } = this.$root.useRoute.params;
    if (examId > 0) {
      this.pageForm.exam_id = examId;
      this.getList();
    }
  },
  methods: {
    // 获取行的颜色
    getRowClassName({
      row
    }) {
      if (row.exam_result_ocr_finish_count < row.exam_result_ocr_count || row.exam_result_check_finish_count < row.exam_result_check_count) {
        // return 'my-row-red'
        return '';
      }
    },
    getRoleOptions() {
      adminRoleOptions().then(res => {
        this.role_options = res.data || [];
      });
    },
    handleRoleChange(key) {
      if (key === 'ocr_role_id') {
        this.pageForm.ocr_user = [];
        this.ocrUsers = [];
      } else if (key === 'check_role_id') {
        this.pageForm.check_user = [];
        this.checkUsers = [];
      } else {
        return;
      }
      this.getRoleUserOptions(key);
    },
    getRoleUserOptions(key) {
      roleUserOptions(this.pageForm[key]).then(res => {
        let data = res.data || [];
        if (key === 'ocr_role_id') {
          data.map(item => item.checked = this.ocrIds.includes(Number(item.id)));
          this.ocrUsers = data;
        }
        if (key === 'check_role_id') {
          data.map(item => item.checked = this.checkIds.includes(Number(item.id)));
          this.checkUsers = data;
        }
      });
    },
    chooseUser(key, val) {
      val.checked = !val.checked;
      if (key === 'ocr') {
        let checkedUser = [];
        for (let item of this.ocrUsers) {
          if (item.checked) {
            var _user;
            // 获取原来数据
            let user = null;
            for (let cor of this.pageForm.ocr_user) {
              if (cor.user_id == item.id) {
                user = {
                  ...cor
                };
                break;
              }
            }
            checkedUser.push({
              user_id: item.id,
              user_name: item.name,
              num: ((_user = user) === null || _user === void 0 ? void 0 : _user.num) || ''
            });
          }
        }
        this.pageForm.ocr_user = checkedUser;
      }
      if (key === 'check') {
        let checkedUser = [];
        for (let item of this.checkUsers) {
          if (item.checked) {
            var _user2;
            // 获取原来数据
            let user = null;
            for (let cor of this.pageForm.check_user) {
              if (cor.user_id == item.id) {
                user = {
                  ...cor
                };
                break;
              }
            }
            checkedUser.push({
              user_id: item.id,
              user_name: item.name,
              num: ((_user2 = user) === null || _user2 === void 0 ? void 0 : _user2.num) || ''
            });
          }
        }
        this.pageForm.check_user = checkedUser;
      }
    },
    getList() {
      examQuestPage(this.pageForm.exam_id, this.pageInfo).then(res => {
        let data = res.data;
        this.tableData = data.data;
        this.pageInfo.current = data.current_page;
        this.pageInfo.total = Number(data.total);
      });
    },
    // 显示弹框
    handleShow(item) {
      if (!item) return;
      if (item && !item.exam_result_check_count && !item.exam_result_ocr_count) return;
      this.curItem = item;
      this.checkUsers = [];
      this.ocrUsers = [];
      //
      let ocr_user = [],
        check_user = [],
        ocr_role_id = '',
        check_role_id = '';
      if (item.examCheckUserOcr && item.examCheckUserOcr.length > 0) {
        var _item$examCheckUserOc;
        ocr_role_id = ((_item$examCheckUserOc = item.examCheckUserOcr[0]) === null || _item$examCheckUserOc === void 0 || (_item$examCheckUserOc = _item$examCheckUserOc.user) === null || _item$examCheckUserOc === void 0 ? void 0 : _item$examCheckUserOc.role_id) || '';
        item.examCheckUserOcr.map(item => {
          ocr_user.push({
            user_id: item.user_id,
            user_name: item.user.name,
            num: item.check_num
          });
        });
      }
      if (item.examCheckUserCheck && item.examCheckUserCheck.length > 0) {
        var _item$examCheckUserCh;
        check_role_id = ((_item$examCheckUserCh = item.examCheckUserCheck[0]) === null || _item$examCheckUserCh === void 0 || (_item$examCheckUserCh = _item$examCheckUserCh.user) === null || _item$examCheckUserCh === void 0 ? void 0 : _item$examCheckUserCh.role_id) || '';
        item.examCheckUserCheck.map(item => {
          check_user.push({
            user_id: item.user_id,
            user_name: item.user.name,
            num: item.check_num
          });
        });
      }
      this.pageForm = {
        exam_id: item.exam_id,
        question_id: item.question_id,
        ocr_role_id: ocr_role_id,
        check_role_id: check_role_id,
        ocr_user,
        check_user
      };
      if (this.pageForm.ocr_role_id > 0) {
        this.getRoleUserOptions('ocr_role_id');
      }
      if (this.pageForm.check_role_id > 0) {
        this.getRoleUserOptions('check_role_id');
      }
      this.dialogVisible = true;
    },
    submit(formName) {
      let that = this;
      that.$refs[formName].validate(valid => {
        if (!valid) return;
        console.log('分布任务---提交数据', that.pageForm);
        saveCheckTask(that.pageForm).then(res => {
          if (res.success) {
            that.getList();
            that.$root.ElMessage({
              type: 'success',
              message: '操作成功'
            });
            that.dialogVisible = false;
          }
        });
      });
    },
    //
    handleSizeChange(e) {
      //修改每页显示条数
      this.pageInfo.pageSize = e;
      this.pageInfo.current = 1;
      this.getList();
    },
    handleCurrentChange(e) {
      this.pageInfo.current = e;
      this.getList();
    }
  }
};