import { addExam, getActiveList, getCardTypeList, getSubjectList } from "@/api/api";
import { examNoInputType } from "@/utils/config";
export default {
  name: "index",
  data() {
    return {
      token: localStorage.getItem('token'),
      Info: {
        id: '',
        name: '',
        active_id: '',
        // 考试活动id
        subject_id: '',
        exam_card_id: '',
        no_input_type: '',
        exam_no_count: '',
        exam_no_have_tableline: 0,
        score: ''
      },
      activeList: [],
      subjectList: [],
      examCardType: [],
      examNoInputType: examNoInputType,
      rules: {
        name: [{
          required: true,
          message: '请选择用户类型',
          trigger: 'blur'
        }],
        subject_id: [{
          required: true,
          message: "请选择考试科目",
          trigger: 'blur'
        }],
        active_id: [{
          required: true,
          message: '请考试活动',
          trigger: 'blur'
        }],
        exam_card_id: [{
          required: true,
          type: 'number',
          min: 1,
          message: '请选择答题卡类型',
          trigger: 'blur'
        }],
        score: [{
          required: true,
          message: '请输入考试总分',
          trigger: 'blur'
        }]
      }
    };
  },
  mounted() {
    this.getActiveOptions();
    this.getSubjectOptions();
    this.getCardTypeOptions();
    let {
      Info
    } = this.$root.useRoute.query;
    if (Info) {
      Info = JSON.parse(Info);
      Object.keys(this.Info).forEach(key => {
        this.Info[key] = Info[key] || '';
      });
    }
  },
  methods: {
    // 获取科目选项
    getSubjectOptions() {
      getSubjectList().then(res => {
        this.subjectList = res.data;
      });
    },
    // 获取活动选项
    getActiveOptions() {
      //获取 学校 年级 班级
      getActiveList().then(res => {
        this.activeList = res.data;
      });
    },
    // 获取答题卡类型选项
    getCardTypeOptions() {
      getCardTypeList().then(res => {
        if (res.success) {
          this.examCardType = res.data;
        }
      });
    },
    //提交
    subData() {
      this.$refs['Info'].validate(valid => {
        if (valid) {
          //delete this.Info.role;//删除  属性
          addExam(this.Info).then(res => {
            if (res.success) {
              this.$root.ElMessage({
                type: 'success',
                message: res.msg
              });
              this.$root.useRouter.go(-1);
            } else {
              this.$root.ElMessage.error(res.msg);
            }
          });
        }
      });
    },
    closeDiv() {
      // this.$emit('closeDiv');
      this.$root.useRouter.go(-1);
    }
  }
};