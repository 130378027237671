import "core-js/modules/es.array.push.js";
import { login } from "@/api/api";
import { autoLogin } from "@/api/mobile";
import { refreshPermissions } from "@/utils/common";
export default {
  data() {
    return {
      type: 1,
      loginInfo: {
        user_no: 'admin',
        password: '123456'
      },
      autoLogin: false,
      clientHeight: '',
      clientWidth: '',
      marginTOP: 300,
      ticket: ''
    };
  },
  mounted() {
    // this.$root 获取App.vue中的变量和方法
    this.getUrlParam(); //获取参数,用于移动端 用户三方登录
    let that = this;
    this.clientHeight = `${document.documentElement.clientHeight}`;
    this.clientWidth = `${document.documentElement.clientWidth}`;
    let s = (this.clientHeight - 500) / 2;
    if (s < 0) s = 0;
    this.marginTOP = s;
    window.onresize = function () {
      this.clientHeight = `${document.documentElement.clientHeight}`;
      this.clientWidth = `${document.documentElement.clientWidth}`;
      let s = (this.clientHeight - 500) / 2;
      if (s < 0) s = 0;
      this.marginTOP = s;
      that.$forceUpdate();
    };
  },
  methods: {
    getUrlParam() {
      //获取参数
      var url = window.location.href; //获取当前url
      var dz_url = url.split('#')[0]; //获取#/之前的字符串
      var cs = dz_url.split('?')[1]; //获取?之后的参数字符串
      if (!cs) {
        return false;
      }
      var cs_arr = cs.split('&'); //参数字符串分割为数组
      var cs = {};
      for (var i = 0; i < cs_arr.length; i++) {
        //遍历数组，拿到json对象
        cs[cs_arr[i].split('=')[0]] = cs_arr[i].split('=')[1];
      }
      this.ticket = cs.ticket; //这样就拿到了参数中的数据
      if (this.ticket) {
        this.autoUserLogin();
      }
    },
    autoUserLogin() {
      //用户端三方登录
      autoLogin(this.ticket).then(res => {
        if (res.data) {
          this.$root.ElMessage({
            message: res.message,
            type: 'success'
          });
          window.localStorage.setItem('token', res.data);
          this.$root.useRouter.push("/mobile/index");
        } else {
          this.$root.ElMessage.warning({
            message: '第三方登录已失效'
          });
          this.$root.useRouter.push("/mobile/login");
        }
      });
    },
    onSubmit() {
      if (!this.loginInfo.user_no) {
        this.$root.ElMessage.error({
          message: '请输入用户名'
        });
        return false;
      }
      if (!this.loginInfo.password) {
        this.$root.ElMessage.error({
          message: '请输入密码'
        });
        return false;
      }
      login(this.loginInfo).then(res => {
        if (res.success) {
          this.$root.ElMessage({
            message: res.msg,
            type: 'success'
          });
          window.localStorage.setItem('user', JSON.stringify(res.data));
          window.localStorage.setItem('token', res.data2);
          this.$root.getMenuList();
          refreshPermissions();
          this.$root.useRouter.push("/home");
        } else {
          this.$root.ElMessage.error({
            message: res.msg
          });
        }
      });
    }
  },
  wacth: {
    clientHeight() {
      this.changeFixed(this.clientHeight);
    }
  }
};