import "core-js/modules/es.array.push.js";
import { getStudentAnswerInfo, saveChangeScore, resetCheckState, getBeforeStudentAnswerInfo } from "@/api/api";
import { Position } from "@element-plus/icons-vue";
import ImageViewer from "@/components/image-viewer.vue";
export default {
  name: "examList",
  components: {
    ImageViewer,
    Position
  },
  data() {
    return {
      Info: null,
      maxScore: null,
      // 满分
      score: '',
      //当前评分
      scoreArr: [],
      // 键盘输入分数
      examData: {
        exam_id: '',
        question_no: ''
      },
      //
      is_auto: false,
      // 自动提交
      auto_seconds: 3,
      // 3秒后自动提交
      timer: null,
      //
      is_ai: true,
      // AI批改显示
      //
      res_is_json: false,
      judge_result: null
    };
  },
  mounted() {
    let data = this.$root.useRoute.query;
    this.examData.exam_id = data.exam_id;
    this.examData.question_no = data.question_no;
    this.getInfo();
  },
  beforeUnmount() {
    this.resetCheckState();
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  },
  methods: {
    search() {
      this.getList();
    },
    resetData() {
      this.score = '';
      this.scoreArr = [];
      this.auto_seconds = 3;
    },
    getInfo() {
      let that = this;
      getStudentAnswerInfo(that.examData).then(res => {
        var _res$data;
        if (!res.data) {
          that.$root.ElMessage({
            type: 'success',
            message: res.msg
          });
          that.$root.useRouter.push({
            path: "/examCheck/examCheckQuesList",
            query: {
              examId: that.examData.exam_id
            }
          });
          return;
        }
        that.resetData();
        that.Info = res.data;
        that.score = res.data.score > 0 ? String(res.data.score) : 0;
        if (that.score > 0) {
          that.scoreArr = [this.score];
        }
        that.maxScore = ((_res$data = res.data) === null || _res$data === void 0 || (_res$data = _res$data.question) === null || _res$data === void 0 ? void 0 : _res$data.score) || 0;

        // 自动提交
        if (that.is_auto) {
          that.countDown();
        }
        // ai分析显示问题
        try {
          that.judge_result = JSON.parse(res.data.judge_result);
          that.res_is_json = true;
          that.judge_result.map(item => {
            that.$nextTick(() => {
              that.renderMath('AiStudent', item['学答步骤']);
              that.renderMath('AiStandard', item['标答步骤']);
              that.renderMath('AiReason', item['判断理由']);
            });
          });
        } catch ({}) {
          this.res_is_json = false;
          this.judge_result = res.data.judge_result || '';
        }
      });
    },
    renderMath(eleClass, innerText) {
      document.getElementsByClassName(eleClass).innerText = innerText;
      if (window.MathJax) {
        window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub, document.getElementsByClassName(eleClass)]);
      }
    },
    showRenew(id) {
      this.dialogFormVisible1 = true;
      this.$refs.renewDiv.getInfo(id); //调用子组件方法
    },
    resetCheckState() {
      //重置状态
      if (!this.Info) return;
      resetCheckState({
        id: this.Info.id
      }).then(res => {
        console.log('重置状态', res);
      });
    },
    // 满分、零分
    handleScore(key) {
      if (key === 'manfen') {
        if (!this.Info) return;
        this.score = this.Info.question.score;
      }
      if (key === 'zero') {
        this.score = 0;
      }
    },
    // 输入分数
    handleChooseScore(str) {
      // 删除分数
      if (str === 'del') {
        this.score = '';
        this.scoreArr = [];
        return;
      }
      if (!this.Info) return;
      this.scoreArr.push(str);
      // 舍弃.5h后面的，.5只能在最后
      let indexToRemoveFrom = this.scoreArr.indexOf('.5');
      if (indexToRemoveFrom !== -1) {
        let res = this.scoreArr.splice(indexToRemoveFrom + 1);
        if (res.length > 0) {
          this.$root.ElMessage.error(`分数只能1位小数（0.5）`);
        }
      }
      const temp = this.scoreArr.join('');
      if (Number(temp) > Number(this.maxScore)) {
        this.$root.ElMessage.error(`分数不能超过满分：${this.maxScore}`);
        return;
      }
      this.score = Number(temp);
    },
    handleAiChange(e) {
      this.is_ai = e;
    },
    handleAutoChange(e) {
      this.is_auto = e;
      this.auto_seconds = 3;
      this.countDown();
    },
    // 倒计时
    countDown() {
      let that = this;
      if (that.timer) clearInterval(that.timer);
      if (that.is_auto) {
        that.timer = setInterval(() => {
          if (that.auto_seconds < 1) {
            clearInterval(that.timer);
            that.auto_seconds = 3;
            // 请求数据
            console.log('自动提交', String(that.score));
            if (String(that.score) >= 0) {
              that.saveScore(1);
            } else {
              that.$root.ElMessage.error(`请输入学生得分`);
            }
          } else {
            that.auto_seconds -= 1;
          }
        }, 1000);
      }
    },
    //保存
    saveScore(x) {
      if (!this.Info) return;
      if (String(this.score).length < 1) {
        this.$root.ElMessage.error(`请输入学生得分`);
        return false;
      }
      if (this.score > this.maxScore) {
        this.$root.ElMessage.error(`分数不能超过满分：${this.maxScore}`);
        return false;
      }
      saveChangeScore({
        id: this.Info.id,
        score: Number(this.score)
      }).then(res => {
        // 保存并继续阅卷
        if (x == 1) {
          this.getInfo();
        }
        //保存并退出阅卷
        // if (x == 2) {
        //   this.$root.msgResut(res, this, 'goFor');
        // }
        // 回评上一个
        if (x == 3) {
          this.$root.msgResut(res, this, 'goBefore');
        }
      });
    },
    goFor() {
      let url = '/examCheck/examCheckQuesList?examId=' + this.examData.exam_id;
      window.localStorage.setItem('menuUrl', url);
      this.$root.useRouter.push({
        path: "/examCheck/examCheckQuesList",
        query: {
          examId: this.examData.exam_id
        }
      });
    },
    // 上一份
    goBefore() {
      getBeforeStudentAnswerInfo({
        exam_id: this.examData.exam_id,
        question_no: this.examData.question_no,
        id: this.Info.id
      }).then(res => {
        if (res.success) {
          var _res$data2;
          this.resetData();
          this.Info = res.data;
          this.score = res.data.score > 0 ? String(res.data.score) : 0;
          if (this.score > 0) {
            this.scoreArr = [this.score];
          }
          this.maxScore = ((_res$data2 = res.data) === null || _res$data2 === void 0 || (_res$data2 = _res$data2.question) === null || _res$data2 === void 0 ? void 0 : _res$data2.score) || 0;
        } else {
          this.$root.ElMessage.error(res.msg);
        }
      });
    }
  }
};