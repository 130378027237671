import "core-js/modules/es.array.push.js";
import { ElMessage, ElMessageBox, ElLoading } from 'element-plus'; //全局导入ele 消息
import { useRouter, useRoute } from 'vue-router'; //路由跳转
import { getMyMenu, isMobile, userLoginUser } from "@/api/api";
import { ElConfigProvider } from 'element-plus';
import { projectName } from '@/utils/config.js';
import zhCn from 'element-plus/lib/locale/lang/zh-cn';
import { refreshPermissions } from "@/utils/common";
export default {
  components: {
    ElConfigProvider //展示中文
  },
  name: 'App',
  data() {
    //定义全局变量 其他组件使用 this.$root获取
    return {
      ElMessage: ElMessage,
      ElMessageBox: ElMessageBox,
      ElLoading: ElLoading,
      useRouter: useRouter(),
      useRoute: useRoute(),
      menuList: [],
      projectName: projectName,
      stateList: ['待审核', '通过', '不通过', '退回', '已撤销'],
      locale: zhCn
    };
  },
  mounted() {
    let eqment = this._isMobile();
    if (!eqment && localStorage.getItem('token')) {
      setTimeout(() => {
        if (this.useRoute.name != 'examCardSock') {
          this.getMenuList();
        }
      }, 10);
    }
    localStorage.getItem('token') && refreshPermissions();
  },
  methods: {
    //定义全局方法 其他组件使用 this.$root获取
    _isMobile() {
      //判断当前设备是否是移动端
      return isMobile();
    },
    loadingOpen() {
      let loading = ElLoading.service({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      return loading;
    },
    setRouter() {
      //动态添加路由
      for (let i in this.menuList) {
        let firstM = this.menuList[i];
        if (firstM.url) {
          let rout = {
            path: firstM.url,
            component: () => import('@/view' + firstM.url),
            name: firstM.name,
            meta: {
              title: firstM.title,
              noCache: true
            },
            children: []
          };
          this.useRouter.addRoute('Home', rout);
        } else if (firstM.child.length > 0) {
          for (let j in firstM.child) {
            let secondM = firstM.child[j];
            if (secondM.url) {
              let rout = {
                path: secondM.url,
                component: () => import('@/view' + secondM.url),
                name: secondM.name,
                meta: {
                  title: secondM.title,
                  noCache: true
                },
                children: []
              };
              this.useRouter.addRoute('Home', rout);
            }
          }
        }
      }
    },
    getMenuList() {
      getMyMenu().then(res => {
        this.menuList = res.data;
        // let url = this.getFirstMenuUrl();
        this.setRouter(); //根据菜单注册组件
        setTimeout(() => {
          // if (localStorage.getItem('menuUrl')) {
          //   this.$root.useRouter.push(localStorage.getItem('menuUrl'));
          // } else {
          // 获取当前路由
          const {
            fullPath
          } = this.useRoute;
          this.$root.useRouter.push(fullPath);
          // }
        }, 200);
      });
    },
    // bug:永远为空
    getFirstMenuUrl() {
      let url = '';
      for (let i in this.menuList) {
        if (this.menuList[i].path) {
          url = this.menuList[i].path;
          return url;
        } else {
          if (this.menuList[i].child.length > 0) {
            for (let j in this.menuList[i].child) {
              if (this.menuList[i].child[j].url) {
                return url;
              }
            }
          }
        }
        return url;
      }
    },
    getBase64(file) {
      //将图片转为base64格式
      return new Promise((resolve, reject) => {
        let reader = new FileReader();
        let fileResult = "";
        reader.readAsDataURL(file);
        //开始转
        reader.onload = function () {
          fileResult = reader.result;
        };
        //转 失败
        reader.onerror = function (error) {
          reject(error);
        };
        //转 结束  咱就 resolve 出去
        reader.onloadend = function () {
          resolve(fileResult);
        };
      });
    },
    /* 通用消息弹窗及跳转 */
    msgResut(res, object, fnc) {
      if (res.success) {
        this.$root.ElMessage({
          type: 'success',
          message: res.msg
        });
        if (object) {
          if (fnc) {
            object[fnc]();
          }
        } else {
          if (fnc) {
            this.useRouter.push({
              path: fnc
            });
          }
        }
      } else {
        this.$root.ElMessage.error(res.msg);
      }
    },
    /* 通用对话框模板 */
    msgTalk(text, object, fnc, data) {
      this.ElMessageBox.confirm(text, '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning',
        draggable: true
      }).then(() => {
        if (object) {
          if (fnc) {
            object[fnc](data);
          }
        } else {
          if (fnc) {
            this.useRouter.push({
              path: fnc
            });
          }
        }
      });
    },
    /* 通用下载模板 */
    downFile(name, fileName) {
      let a = document.createElement("a"); //创建一个<a></a>标签
      a.href = "/template/" + name; // 给a标签的href属性值加上地址，注意，这里是绝对路径，不用加 点.
      a.download = fileName || name; //设置下载文件文件名，这里加上.xlsx指定文件类型，pdf文件就指定.fpd即可
      a.style.display = "none"; // 障眼法藏起来a标签
      document.body.appendChild(a); // 将a标签追加到文档对象中
      a.click(); // 模拟点击了a标签，会触发a标签的href的读取，浏览器就会自动下载了
    }
  }
};