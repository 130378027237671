import "core-js/modules/es.array.push.js";
import { getExamPaiweiPage } from "@/api/api";
export default {
  name: "activeList",
  data() {
    return {
      tableData: [],
      dataInfo: [],
      Info: null,
      dialogFormVisible: false,
      pageInfo: {
        pageSize: 10,
        active_id: '',
        type: 4,
        name: '',
        exam_no: '',
        current: 1,
        total: 0
      },
      exportIds: ''
    };
  },
  mounted() {
    let data = this.$root.useRoute.query;
    if (data) {
      this.pageInfo.active_id = data.id;
    }
    this.getList();
  },
  methods: {
    reset() {
      this.pageInfo.name = '';
      this.pageInfo.exam_no = '';
    },
    search() {
      this.pageInfo.pageSize = 10;
      this.pageInfo.current = 1;
      this.getList();
    },
    getList() {
      getExamPaiweiPage(this.pageInfo).then(res => {
        this.pageInfo.total = parseInt(res.data.total);
        this.tableData = res.data.records;
      });
    },
    showEdit(id) {
      let data = id || '';
      this.$root.useRouter.push({
        path: "/examSetting/examSortDo",
        query: {
          id: data
        }
      });
    },
    showRenew(id) {
      this.dialogFormVisible1 = true;
      this.$refs.renewDiv.getInfo(id); //调用子组件方法
    },
    closeDiv() {
      this.dialogFormVisible = false;
    },
    handleSizeChange(e) {
      //修改每页显示条数
      this.pageInfo.pageSize = e;
      this.pageInfo.current = 1;
      this.getList();
    },
    handleCurrentChange(e) {
      this.pageInfo.current = e;
      this.getList();
    }
  }
};