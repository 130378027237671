import "core-js/modules/es.array.push.js";
import { examInfo, saveCardPosition } from "@/api/api";
import $ from 'jquery';
export default {
  name: "index",
  data() {
    return {
      type: 1,
      //1:正面 2：反面
      Info: {
        id: ''
      },
      offsetXY: [],
      paperWidth: 1280,
      paperHeight: 1123,
      drawerTml: false
    };
  },
  created() {
    window.deleteBN = this.deleteBN;
  },
  mounted() {
    $(document).scrollTop(0);
    let data = this.$root.useRoute.query;
    this.Info.id = data.id;
    this.getInfo();
    this.init();
  },
  methods: {
    init() {
      let that = this;
      let drawArear = document.getElementById('drawArear'); //document.getElementById('drawArear'); // 获取画布元素
      let drawReact = document.getElementById('drawReact'); // 获取矩形框元素
      let areaInfo = drawArear.getBoundingClientRect(); // 返回元素的大小及其相对于视口的位置
      let reactWidth, reactHeight, reactTop, reactLeft; // 定义矩形框的宽、高、top、left
      let drawFlag = true; // 定义矩形框绘制标识
      let beginPoint = {}; // 标记起点
      let endPoint = {}; // 标记终点
      drawArear.onmousedown = function ($event) {
        var scrollY = document.documentElement.scrollTop || document.body.scrollTop;
        drawReact.style.width = '10px'; // 宽
        drawReact.style.height = '10px'; // 高
        // if (!drawFlag) {
        //   return;
        // }
        drawFlag = true;
        drawReact.style.visibility = 'visible'; // 进入画布按下鼠标显示默认矩形框
        // 鼠标按下的位置作为矩形框的顶点
        beginPoint = {
          x: $event.clientX - areaInfo.x,
          y: $event.clientY - areaInfo.y
        };
        reactTop = $event.clientY - areaInfo.y;
        reactLeft = $event.clientX - areaInfo.x;
        drawReact.style.top = reactTop + scrollY + 'px';
        drawReact.style.left = reactLeft + 'px';
      };
      // 绑定鼠标事件--onmousemove
      drawReact.onmousemove = function ($event) {
        if (!drawFlag) {
          return;
        }
        // 鼠标移动的坐标 - 画布相对视窗的位置 - 矩形相对于画布的偏差（顶点位置） = 矩形框的大小
        reactWidth = $event.clientX - areaInfo.x + 10; // 宽
        reactHeight = $event.clientY - areaInfo.y + 10; // 高

        reactWidth = $event.clientX > areaInfo.right ? areaInfo.width + areaInfo.x - beginPoint.x : $event.clientX - areaInfo.x + 10;
        reactHeight = $event.clientY > areaInfo.bottom ? areaInfo.height + areaInfo.y - beginPoint.y : $event.clientY - areaInfo.y + 10;
        drawReact.style.width = reactWidth - reactLeft + 'px'; // 宽
        drawReact.style.height = reactHeight - reactTop + 'px'; // 高
        console.log(drawReact);
      };
      // 绑定鼠标事件--onmousedown
      drawReact.onmouseup = function ($event) {
        drawFlag = false;
        // 鼠标弹起的点作为矩形框的终点
        let x = beginPoint.x + areaInfo.x;
        let y = beginPoint.y + areaInfo.y + scrollY;
        let xy = beginPoint.x + areaInfo.x + reactWidth - reactLeft;
        let yx = beginPoint.y + areaInfo.y + reactHeight - reactTop;
        endPoint = {
          x: $event.clientX - areaInfo.x,
          y: $event.clientY - areaInfo.y
        };
        let kuangcengNum = document.getElementsByClassName('kuangceng').length;
        let html = "<div class='kuangceng' name='zf" + that.type + "' style='position: absolute;top:" + y + "px;left:" + x + "px;width: " + drawReact.style.width + ";height: " + drawReact.style.height + ";z-index: 100;border: 2px dashed red'>" + "<div class='kuangcengSort'>" + kuangcengNum + "</div>" + "<div class='kuangcengDel' onclick='deleteBN(this)' title='删除' style='position: relative;top:-14px;left: 0'>x</div>" + "</div>";
        document.body.insertAdjacentHTML('beforeend', html);
        let sop = {
          type: that.type,
          //1正面 2反面
          sort: kuangcengNum,
          //序号 画框的顺序
          question_no: kuangcengNum,
          //题号
          x: x,
          y: y,
          width: reactWidth - reactLeft,
          height: reactHeight - reactTop,
          is_exam_no: 0,
          //是否是考号
          g_type: '1',
          //1：主观题 2：客观题 默认主观题
          question_row: 5,
          //框内题号分布 行数
          question_column: 1,
          //框内题号分布 列数
          is_fill_col_first: 1 //1：纵向题号 0：横向题号
        };
        that.offsetXY.push(sop);
        drawReact.style.visibility = 'hidden'; // 进入画布按下鼠标显示默认矩形框
      };
    },
    getInfo() {
      examInfo(this.Info).then(res => {
        this.Info = res.data;
        if (this.Info.exam_card) {
          this.paperWidth = this.Info.exam_card.width_px;
          this.paperHeight = this.Info.exam_card.height_px;
        }
      });
    },
    deleteBN(e) {
      var ob = e.parentNode;
      let s = document.querySelectorAll('.kuangceng');
      var num = Array.prototype.indexOf.call(s, ob);
      this.offsetXY.splice(num, 1);
      ob.remove();
    },
    saveModel() {
      saveCardPosition({
        exam_id: this.Info.id,
        offsetXY: this.offsetXY
      }).then(res => {
        this.$root.ElMessageBox.alert(res.msg);
        if (res.success) {
          setTimeout(res => {
            window.close();
          }, 1500);
        }
      });
    },
    changePage() {
      $(document).scrollTop(0);
      if (this.type == 1) {
        $("div[name='zf1']").hide();
        $("div[name='zf2']").show();
        this.type = 2;
      } else {
        $("div[name='zf2']").hide();
        $("div[name='zf1']").show();
        this.type = 1;
      }
    },
    handleShow() {
      this.drawerTml = true;
    },
    handleClose() {
      this.drawerTml = false;
    }
  }
};