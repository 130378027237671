import http from '@/utils/request.js'
import {clearPermissions} from "@/utils/common";

export function login(data) {
  return http.post('/admin/user_login/login', data)
}

export function loginOut() {
  localStorage.clear();
  clearPermissions();
}

export function isMobile() {//判断当前设备是否是移动端
  let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
  return flag;
}

/**
 * 获取所有菜单权限
 */
export function getMenus() {
  return http.get('/admin/role/menus')
}

/**
 * 获取所有菜单权限
 */
export function getPermissionMenus() {
  return http.get('/admin/permission/menus')
}

export function roleStore(data) {
  return http.post('/admin/permission/roleStore', data)
}

// 获取权限详情 /admin/permission/info?id=1
export function getPermissionInfo(id) {
  return http.get(`/admin/permission/info?id=${id}`)
}

/* 菜单 */
export function getMenu() {
  return http.get('/admin/admin_menu/menu_all')
}

export function getMyMenu() {
  return http.get('/admin/admin_menu/role_menu')
}

/**
 * 获取用户菜单和权限
 */
export function userLoginUser() {
  return http.get('/admin/permission/user')
}

/* 角色 */
export function addRole(data) {
  return http.post('/admin/admin_role/role_edit', data)
}

export function getRolePage(data) {
  return http.get('/admin/admin_role/role_page', data)
}

export function getRoleList(data) {
  return http.get('/admin/admin_role/role_list', data)
}

/* 用户 */
export function getUserPage(data) {
  return http.get('/admin/admin_user/user_page', data)
}

export function addUser(data) {
  return http.post('/admin/admin_user/edit', data)
}

export function deleteUser(data) {//删除用户
  return http.post('/admin/admin_user/delete_user', data)
}

export function resetPassword(data) {//重置用户密码
  return http.post('/admin/admin_user/reset_user_password', data)
}

export function changePass(data) {//修改用户密码
  return http.post('/currency/common_user/change_password', data);
}

//导入学生
export function importStudent(data) {
  return http.file('/admin/admin_user/student_import', data)
}

//导入教师
export function importTeacher(data) {
  return http.file('/admin/admin_user/teacher_import', data)
}

/* 学校 年级、班级 */
export function importClass(data) {//导入班级
  return http.file('/school/school_team/school_class_import', data)
}

/* 科目 */
export function getSubjectPage(data) {
  return http.get('/admin/admin_subject/subject_page', data)
}

export function getSubjectList(data = {}) {
  return http.get('/admin/admin_subject/subject_list', data)
}

export function saveSubject(data) {
  return http.post('/admin/admin_subject/subject_edit', data)
}

export function deleteSubject(id) {
  return http.post('/admin/admin_subject/subject_delete', {
    id: id
  })
}


/* 考试 */
export function getActivePage(data) {
  return http.get('/admin/active_exam/active_page', data)
}

export function getActiveList(data = {}) {
  return http.get('/admin/active_exam/active_list', data)
}

export function addActive(data) {
  return http.post('/admin/active_exam/active_edit', data)
}

export function getExamPage(data) {
  return http.getNoLoading('/admin/active_exam/exam_page', data)
}

export function addExam(data) {
  return http.post('/admin/active_exam/exam_edit', data)
}

export function exam_close(data) {//关闭操作 切割 上传 同步
  return http.post('/admin/active_exam/exam_close', data)
}

/* 考试排位 */
export function examPaiwei(data) {
  return http.file('/admin/exam_setting/paiwei', data)
}

export function getExamPaiweiPage(data) {
  return http.get('/admin/exam_setting/paiwei_page', data)
}

export function examInfo(data) {
  return http.get('/admin/active_exam/exam_info', data);
}

/* end */

/* 试题 */
export function getQuestionTypeAllList(data) {
  return http.get('/question/question_type_manage/list', data)
}

export function getKDQuestionList(data) {
  return http.get('/question/question_manage/get_third_question_list', data)
}

/* end */

/* 考试阅卷任务分配 start */
export function getCheckTaskQuestion(data) {
  return http.get('/admin/exam_setting/paper_question_list', data)
}

export function getCheckTeacherList(data) {
  return http.get('/admin/exam_setting/paper_check_teacher_list', data)
}

export function paperCheckTeacherTask(data) {
  return http.post('/admin/exam_setting/paper_check_teacher_task', data)
}

/* end */

/* 考试阅卷批阅 start */
export function getMyExamCheckList(data) {//获取自己可批阅的考试列表 管理员可查看全部
  return http.get('/admin/admin_exam_check/my_exam_check', data)
}

export function getMyExamCheckQuesList(data) {//获取考试列表 的题目
  return http.get('/admin/admin_exam_check/my_exam_check_question_list', data)
}

export function getStudentAnswerInfo(data) {//获取考试学生答题详情 进行批阅
  return http.get('/admin/admin_exam_check/student_answer_info', data)
}

export function getBeforeStudentAnswerInfo(data) {//回评上一个学生答题详情 进行批阅
  return http.get('/admin/admin_exam_check/before_student_answer_info', data)
}

export function resetCheckState(data) {//批阅中页面异常关闭 退出 刷新 批阅状态还原
  return http.post('/admin/admin_exam_check/check_state_reset', data)
}

export function kgCheckAuto(data) {//客观题批量识别
  return http.post('/admin/admin_exam_card/kg_check_paper', data)
}

export function zgCheckAuto(data) {//主观题判别（科大）
  return http.post('/admin/admin_exam_card/zg_check_paper', data)
}

/* end */

/* 答题卡 start */
export function getCardTypeList() {
  return http.get('/admin/admin_exam_card/list')
}

export function getCardPositionList(data) {//获取答题卡每个题框选的坐标及对应题号 主客观题 考号图片坐标列表
  return http.get('/admin/admin_exam_card/get_card_position_list', data)
}

export function saveCardPosition(data) {//保存答题卡每个题框选的坐标及对应题号 主客观题 考号图片坐标
  return http.post('/admin/admin_exam_card/save_card_position', data)
}

export function cutCardPaper(data) {//扫描目录切割答题卡
  return http.get('/admin/admin_exam_card/cut_paper', data)
}

export function knowCardPaper(data) {//扫描目录识别答题卡考号
  return http.post('/admin/admin_exam_card/know_exam_no', data)
}

export function getKnowCardPage(data) {//获取考试识别结果列表
  return http.get('/admin/admin_exam_card/get_know_error_list', data);
}

export function EditKnowExamNo(data) {//获取考试识别结果列表
  return http.post('/admin/admin_exam_card/change_exam_no', data);
}

export function uploadCutCardPaper(data) {//上传学生答案判卷
  return http.post('/admin/admin_exam_card/new_check_paper', data)
}

export function checkPngImg(data) {//检查图片
  return http.post('/admin/admin_exam_card/check_img_png', data)
}

export function rotateImg(data) {//检查旋转图片
  return http.post('/admin/admin_exam_card/check_img_rotate', data)
}

export function pianyiCheckImg(data) {//根据图片偏移获取结果
  return http.get('/admin/admin_exam_card/check_pianyi_img_kd', data);
}

export function pianyiResultInfo(data) {//根据图片偏移获取结果
  return http.get('/admin/admin_exam_card/pianyiInfo', data);
}

export function examStep(data) {//步骤一、二操作 偏移矫正 切割 考号识别
  return http.postNoLoading('/admin/admin_exam_card/exam_step', data);
}

export function examStepProcess(data) {//获取进度
  return http.postNoLoading('/admin/admin_exam_card/exam_step_process', data);
}

/* 答题卡 end */

/* 考试数据 start */
export function getExamUserPage(data) {//获取考试参与的学生
  return http.get('/admin/admin_exam_user/page', data)
}

export function syncPaperCheckData(data) {//同步数据
  return http.post('/admin/admin_exam_data/exam_data_sync', data)
}

export function statExamScore(data) {//统计成绩
  return http.post('/admin/admin_exam_data/stat_scores', data)
}

export function getExamUserQuestionPage(data) {//获取学生的 答题题目列表
  return http.get('/admin/admin_exam_user/question_list', data)
}

export function getStudentQuestionInfo(data) {//学生题目详情
  return http.get('/admin/admin_exam_user/question_info', data)
}

export function saveChangeScore(data) {//试题得分修改保存
  return http.post('/admin/admin_exam_user/question_score_save', data)
}

export function exportExamData(data) {//导出数据
  return http.downFile('/admin/admin_exam_data/export_exam_data', data)
}

export function exportExamAllData(data) {//导出数据(多科)
  return http.downFile('/admin/admin_exam_data/export_all_exam_data', data)
}

export function exportExamOneData(data) {//导出数据（单科）
  return http.downFile('/admin/admin_exam_data/export_one_exam_data', data)
}

export function createExamAllClassExcel(data) {
  return http.post('/admin/admin_exam_data/create_all_class_excel', data)
}

/* 考试数据 end */

/* 公用方法 */
export function addData(data, name) {
  data['object_name'] = name;
  return http.post('/admin/common_api/add', data)
}

export function getPage(data, name) {
  data['object_name'] = name;
  return http.get('/admin/common_api/page', data)
}

export function getList(data, name) {
  data['object_name'] = name;
  return http.get('/admin/common_api/list', data)
}

export function uploadFile(data) {//上传图片
  return http.file('/currency/common_method/upload_file', data)
}

export function downFile(name) {//下载模板
  return http.down('/' + name + '/download?Authorization=Bearer ' + localStorage.getItem('token'));
}

export function exportFile(url) {//导出
  return http.down(url);
}

/*  */
export function getQuestionBySubject(data) {
  return http.get('/question/question_manage/get_question_by_subject', data);
}

/* 科大第三方接口 */
export function checkPageKD(data) {
  return http.post('/admin/admin_scanning/kd_check_page', data)
}

/* 切割图片 */
export function cropImg(data) {
  return http.post('/currency/common_method/crop_img', data)
}

// ---------检查管理---------
// 获取当前所有考试列表
export function ckExamPage(params = {}) {
  return http.get(`/admin/check_manage/active`, params)
}

// 获取当前考试得所有题目
export function examQuestPage(exam_id, params = {}) {
  return http.get(`/admin/check_manage/tasks?exam_id=${exam_id}`, params)
}

//保存OCR校验结果
export function saveOcrResult(data) {
  return http.post('/admin_exam_ocr/save_ocr_result', data)
}

// 后台角色选项
export function adminRoleOptions() {
  return http.get('/admin/check_manage/optionRoles')
}

export function roleUserOptions(role_id) {
  return http.get(`/admin/check_manage/optionUsers?role_id=${role_id}`)
}

// 保存识别任务分配
export function saveCheckTask(data) {
  return http.post('/admin/check_manage/storeTask', data)
}

/**
 * 检查识别-考试列表
 * @param params
 * @returns {Promise | Promise<unknown>}
 */
export function getMyExamOcrCheckList(params) {
  return http.get('/admin/AdminExamOcr/active', params)
}

/**
 * 识别检查-试题筛选(题型)选项
 * @param params
 * @returns {Promise | Promise<unknown>}
 */
export function getMyExamOcrQuestionOptions(params) {
  return http.get('/admin/AdminExamOcr/optionQuestion', params)
}

/**
 * 识别检查-获取题
 * @param params
 * @returns {Promise | Promise<unknown>}
 */
export function getMyExamOcrQuestionInfo(params) {
  return http.get('/admin/admin_exam_ocr/questionOcr', params)
}

/**
 * 识别检查-获取题
 * @param data
 * @returns {Promise<unknown>}
 */
export function saveMyExamOcrQuestion(data) {
  return http.post('/admin/admin_exam_ocr/save_ocr_result', data)
}

/**
 * 识别检查-重新判卷
 * @param data
 * @returns {Promise<unknown>}
 */
export function myExamOcrResetJugdePaper(data) {
  return http.post('/admin/admin_exam_ocr/reset_jugde_paper', data)
}

/**
 * 识别检查-获取进度
 * @param params
 * @returns {Promise | Promise<unknown>}
 */
export function getMyExamOcrRate(params) {
  return http.get('/admin/admin_exam_ocr/ocrRate', params)
}

/**
 * 判卷检查-获取题
 * @param params
 * @returns {Promise | Promise<unknown>}
 */
export function getMyExamCheckQuestionInfo(params) {
  return http.get('/admin/admin_exam_ocr/questionCheck', params)
}

/**
 * 判卷检查-保存
 * @param data
 * @returns {Promise | Promise<unknown>}
 */
export function saveCheckExamResult(data) {
  return http.post('/admin/admin_exam_ocr/saveCheckResult', data)
}

/**
 * 判卷检查-获取进度
 * @param data
 * @returns {Promise | Promise<unknown>}
 */
export function checkExamRate(data) {
  return http.get('/admin/admin_exam_ocr/checkRate', data)
}

/**
 * 学情分析-学科统计
 * @param params
 * @returns {Promise | Promise<unknown>}
 */
export function xueQingStatInfo(params) {
  return http.get('/admin/ExamScore/subject', params)
}

/**
 * 学情分析-修改分数线
 * @param data
 * @returns {Promise | Promise<unknown>}
 */
export function xueQingStatUpdateStandard(data) {
  return http.post('/admin/ExamScore/editLineScoreName', data)
}

/**
 * 学情分析-学科统计导出
 * @param params
 * @returns {Promise | Promise<unknown>}
 */
export function xueQingStatDownload(params) {//导出数据(多科)
  return http.downFile('/admin/ExamScore/subjectExport', params)
}

/**
 * 任课管理-获取列表
 * @param params
 * @returns {Promise | Promise<unknown>}
 */
export function getClassTeacherList(params) {
  return http.get('/admin/admin_clazz_teacher/index', params)
}

/**
 * 任课管理-删除
 * @param params
 * @returns {Promise | Promise<unknown>}
 */
export function deleteClassTeacher(id) {
  return http.delete('/admin/admin_clazz_teacher/delete?id=' + id)
}

/**
 * 任课管理-保存
 * @param params
 * @returns {Promise | Promise<unknown>}
 */
export function storeClassTeacher(params) {
  return http.delete('/admin/admin_clazz_teacher/store', params)
}

/**
 * 任课管理-导入
 * @param params
 * @returns {Promise | Promise<unknown>}
 */
export function importClassTeacher(data) {
  return http.file('/admin/admin_clazz_teacher/import', data)
}

/**
 * 学情分析-考试活动列表
 * @param params
 * @returns {Promise | Promise<unknown>}
 */
export function xueQingActiveTableList(params = {}) {
  return http.get('/admin/ExamScore/active', params)
}

/**
 * 学情分析-活动详情
 * @param active_id
 * @returns {Promise | Promise<unknown>}
 */
export function xueQingActiveDetail(active_id, class_id = '') {
  let url = `/admin/ExamScore/detail?active_id=${active_id}`
  if (class_id > 0) {
    url = url + `&class_id=${class_id}`
  }
  return http.get(url)
}

/**
 * 学情分析-考试活动班级选项
 * @param active_id
 * @returns {Promise | Promise<unknown>}
 */
export function xueQingActiveClassOptions(active_id) {
  return http.get(`/admin/ExamScore/classOption?active_id=${active_id}`)
}


/**
 * 学情分析-排行
 * @param active_id
 * @returns {Promise | Promise<unknown>}
 */
export function xueQingActiveRank(active_id, class_id = '') {
  let url = `/admin/ExamScore/top?active_id=${active_id}`
  if (class_id > 0) {
    url = url + `&class_id=${class_id}`
  }
  return http.get(url)
}

/**
 * 学情分析-上线率
 * @param active_id
 * @returns {Promise | Promise<unknown>}
 */
export function xueQingUpline(active_id, params = {}) {
  return http.get(`/admin/ExamScore/upline`, {
    active_id,
    ...params
  })
}

/**
 * 学情分析-分数段统计
 * @param active_id
 * @param params
 * @returns {Promise | Promise<unknown>}
 */
export function xueQingScoreRange(active_id, params = {}) {
  return http.get(`/admin/ExamScore/scoreStat`, {
    active_id,
    ...params
  })
}

/**
 * 学情分析-考试列表
 * @param active_id
 * @param params
 * @returns {Promise | Promise<unknown>}
 */
export function xueQingExamList(active_id) {
  return http.get(`/admin/ExamScore/exams?active_id=${active_id}`)
}

/**
 *学情分析-试卷分析
 * @param exam_id
 * @param class_id
 * @returns {Promise | Promise<unknown>}
 */
export function xueQingPaperStat(exam_id, class_id = '') {
  return http.get(`/admin/ExamScore/paperStat?exam_id=${exam_id}&class_id=${class_id}`)
}

/**
 * 意见反馈-获取列表
 * @param params
 * @returns {Promise | Promise<unknown>}
 */
export function getResultCommentList(params) {
  return http.get('/admin/result_comment/index', params)
}