import "core-js/modules/es.array.push.js";
import { addUser, getList, getRoleList, getSubjectList } from "@/api/api";
import { userType } from "@/utils/config";
export default {
  name: "index",
  data() {
    return {
      token: localStorage.getItem('token'),
      Info: {
        id: '',
        user_no: '',
        password: '',
        role_id: '',
        school_id: '',
        name: '',
        grade_level: '',
        type: ''
      },
      userType: userType,
      roleList: [],
      subjectList: [],
      schoolList: [],
      gradeList: [],
      subjectIds: [],
      classList: [],
      clazzList: [],
      defaultProps: {
        label: 'name',
        value: 'id'
      },
      type: '',
      rules: {
        type: [{
          required: true,
          message: '请选择用户类型',
          trigger: 'blur'
        }],
        user_no: [{
          required: true,
          message: "请填写用户名/学号/教职工号",
          trigger: 'blur'
        }],
        name: [{
          required: true,
          message: "请填写姓名",
          trigger: 'blur'
        }],
        password: [{
          required: true,
          message: '请填写密码',
          trigger: 'blur'
        }],
        role_id: [{
          required: true,
          message: '请选择角色',
          trigger: 'blur'
        }]
      }
    };
  },
  mounted() {
    let data = this.$root.useRoute.query;
    if (data.Info) {
      this.Info = JSON.parse(data.Info);
      if (!this.Info.school_id) {
        this.Info.school_id = '';
      }
    }
    if (data.type) {
      this.type = parseInt(data.type);
      this.Info.type = parseInt(data.type);
    }
    this.getRoleList();
    this.getSubjectList();
    this.getSGList();
  },
  methods: {
    closeDiv() {
      this.$root.useRouter.go(-1);
    },
    getRoleList() {
      getRoleList().then(res => {
        this.roleList = res.data;
      });
    },
    getSubjectList() {
      getSubjectList().then(res => {
        let subarr = [];
        for (let i in res.data) {
          subarr.push({
            label: res.data[i].name,
            value: res.data[i].id
          });
        }
        this.subjectList = subarr;
        if (this.Info.subject_ids) {
          let subjectIds = this.Info.subject_ids.split(",");
          this.subjectIds = subjectIds.map(function (data) {
            return +data;
          });
        }
      });
    },
    getSGList() {
      //获取 学校 年级 班级
      let data = {};
      getList(data, 'School').then(res => {
        this.schoolList = res.data;
      });
      getList(data, 'Grade').then(res => {
        this.gradeList = res.data;
      });
      getList(data, 'Clazz').then(res => {
        this.classList = res.data;
        this.changeClass();
      });
    },
    changeClass() {
      //学校、年级改变后 筛选班级
      let clazzs = this.classList;
      let colclazz = [];
      for (let i in clazzs) {
        if (clazzs[i].school_id == this.Info.school_id && clazzs[i].grade_level == this.Info.grade_level) {
          colclazz.push(clazzs[i]);
        }
      }
      this.clazzList = colclazz;
    },
    subData() {
      //提交
      if (this.Info.user_no.length < 4 || this.Info.user_no.length > 18) {
        this.$root.ElMessage.error('请输入4~18位用户名！');
        return false;
      }
      // if(!this.Info.id) {
      //   if (this.Info.password.length > 7 && this.Info.password.length < 19) {
      //     let reg = /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\\W_!@#$%^&*`~()-+=]+$)(?![a-z0-9]+$)(?![a-z\\W_!@#$%^&*`~()-+=]+$)(?![0-9\\W_!@#$%^&*`~()-+=]+$)(?![a-zA-Z0-9]+$)(?![a-zA-Z\\W_!@#$%^&*`~()-+=]+$)(?![a-z0-9\\W_!@#$%^&*`~()-+=]+$)(?![0-9A-Z\\W_!@#$%^&*`~()-+=]+$)[a-zA-Z0-9\\W_!@#$%^&*`~()-+=]{8,16}$/;
      //     if (reg.test(this.Info.password)) {
      //       console.log(1);
      //     } else {
      //       this.$root.ElMessage.error('密码必须包含大小写字母、数字和特殊字符！');
      //       return false;
      //     }
      //   } else {
      //     this.$root.ElMessage.error('请输入8~18位密码！');
      //     return false;
      //   }
      // }
      if (this.subjectIds) {
        this.Info.subject_ids = this.subjectIds.join(',');
      }
      this.$refs['Info'].validate(valid => {
        if (valid) {
          //delete this.Info.role;//删除  属性
          addUser(this.Info).then(res => {
            if (res.success) {
              this.$root.ElMessage({
                type: 'success',
                message: res.msg
              });
              this.$root.useRouter.go(-1);
            } else {
              this.$root.ElMessage.error(res.msg);
            }
          });
        }
      });
    },
    handleAvatarSuccess(e) {
      //图片上传成功后
      this.Info.businessLicenseImg = e.data;
    }
  }
};